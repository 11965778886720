import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  netTrial: [],
  tab: 'gst',
  grossTrial: [],
  extendedTrial: [],
  printDate: null,
  lossSales: [],
  lossPurchase: [],
  purchaseData: [],
  saleData: [],
  tradingProfitLoss: [],
  liabilities: [],
  assets: [],
  expanadAll: false,
  expanadAll1: false,
  balanceSheet: [],
  taxReport: [],
  saleCustomer: [],
  saleItem: [],
  saleLocation: [],
  taxReportReturnPeriod: null,
  customerBalances: [],
  aging: [],
  agingDetails: [],
  invoiceDetails: [],
  sundryReport: [],
  estimationReport: [],
  invoiceDetailReport: [],
  payableInvoiceReport: [],
  expenseNatureReport: [],
  expenseByBalance: [],
  expenseByLocation: [],
  expenseLedgerPerson: [],
  purchaseByVendor: [],
  purchaseByItem: [],
  purchaseByLocation: [],
  fixedAssetsReport: [],
  fixedAssetsPartyReport: [],
  fixedAssetsBalanceReport: [],
  // payment
  payment2Register: [],
  payment2Unregister: [],
  paymentByMSME: [],
  paymentViolation43B: [],
  paymentByLocation: [],
  adjustedPayment: [],
  advancedPayment: [],
  payment2Employee: [],
  unAdjustedPayment: [],
  // sales
  salesB2B: [],
  salesB2C: [],
  salesRCMReport: [],
  saleByProductByCustomer: [],
  saleByProductByService: [],
  salesMSMEReport: [],
  purchaseMSMEReport: [],
  expenseMSMEReport: [],

  // deliverychallan
  deliveryChallanReportData: [],
  deliveryTotalProjectCost:[]
};

const slice = createSlice({
  name: 'gst',
  initialState,
  reducers: {
    setDeliveryChallanReportData(state, action) {
      state.deliveryChallanReportData = action.payload;
    },
    setExpanadAll1(state, action) {
      state.expanadAll1 = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setExpanadAll(state, action) {
      state.expanadAll = action.payload;
    },
    setPrintDate(state, action) {
      state.printDate = action.payload;
    },
    setNetTrial(state, action) {
      state.netTrial = action.payload;
    },
    setGrossTrial(state, action) {
      state.grossTrial = action.payload;
    },
    setExtendedTrial(state, action) {
      state.extendedTrial = action.payload;
    },
    setlossSales(state, action) {
      state.lossSales = action.payload;
    },
    setlossPurchase(state, action) {
      state.lossPurchase = action.payload;
    },
    setPurchaseData(state, action) {
      state.purchaseData = action.payload;
    },
    setSaleData(state, action) {
      state.saleData = action.payload;
    },
    setTradingProfitLoss(state, action) {
      state.tradingProfitLoss = action.payload;
    },
    setLiabilities(state, action) {
      state.liabilities = action.payload;
    },
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setBalanceSheet(state, action) {
      state.balanceSheet = action.payload;
    },
    setTaxReport(state, action) {
      state.taxReport = action.payload;
    },
    setSalecustomer(state, action) {
      state.saleCustomer = action.payload;
    },
    setSaleItem(state, action) {
      state.saleItem = action.payload;
    },
    setSaleLocation(state, action) {
      state.saleLocation = action.payload;
    },
    setTaxReportReturnPeriod(state, action) {
      state.taxReportReturnPeriod = action.payload;
    },
    setCustomerBalances(state, action) {
      state.customerBalances = action.payload;
    },
    setAging(state, action) {
      state.aging = action.payload;
    },
    setAgingDetails(state, action) {
      state.agingDetails = action.payload;
    },

    setInvoiceDetails(state, action) {
      state.invoiceDetails = action.payload;
    },
    setSundryReport(state, action) {
      state.sundryReport = action.payload;
    },
    setEstimationReport(state, action) {
      state.estimationReport = action.payload;
    },
    setInvoiceDetailReport(state, action) {
      state.invoiceDetailReport = action.payload;
    },
    setPayableInvoiceReport(state, action) {
      state.payableInvoiceReport = action.payload;
    },
    setExpenseNatureReport(state, action) {
      state.expenseNatureReport = action.payload;
    },
    setExpenseByBalance(state, action) {
      state.expenseByBalance = action.payload;
    },
    setExpenseByLocation(state, action) {
      state.expenseByLocation = action.payload;
    },
    setExpenseLedgerPerson(state, action) {
      state.expenseLedgerPerson = action.payload;
    },
    setPurchaseByVendor(state, action) {
      state.purchaseByVendor = action.payload;
    },
    setPurchaseByItem(state, action) {
      state.purchaseByItem = action.payload;
    },
    setPurchaseByLocation(state, action) {
      state.purchaseByLocation = action.payload;
    },
    setFixedAssetsReport(state, action) {
      state.fixedAssetsReport = action.payload;
    },
    setFixedAssetsPartyReport(state, action) {
      state.fixedAssetsPartyReport = action.payload;
    },
    setFixedAssetsBalanceReport(state, action) {
      state.fixedAssetsBalanceReport = action.payload;
    },

    // payment setPayment2Register
    setPayment2Register(state, action) {
      state.payment2Register = action.payload;
    },
    setPayment2Unregister(state, action) {
      state.payment2Unregister = action.payload;
    },
    setPaymentByMSME(state, action) {
      state.paymentByMSME = action.payload;
    },
    setPaymentViolation43B(state, action) {
      state.paymentViolation43B = action.payload;
    },
    setPaymentByLocation(state, action) {
      state.paymentByLocation = action.payload;
    },
    setAdjustedPayment(state, action) {
      state.adjustedPayment = action.payload;
    },
    setAdvancedPayment(state, action) {
      state.advancedPayment = action.payload;
    },
    setPayment2Employee(state, action) {
      state.payment2Employee = action.payload;
    },
    setUnAdjustedPayment(state, action) {
      state.unAdjustedPayment = action.payload;
    },
    setSalesB2BReport(state, action) {
      state.salesB2B = action.payload;
    },
    setSalesB2CReport(state, action) {
      state.salesB2C = action.payload;
    },
    setSalesRCMReport(state, action) {
      state.salesRCMReport = action.payload;
    },
    setSaleByProductByCustomer(state, action) {
      state.saleByProductByCustomer = action.payload;
    },
    setSaleByProductByService(state, action) {
      state.saleByProductByService = action.payload;
    },
    setSalesMSMEReport(state, action) {
      state.salesMSMEReport = action.payload;
    },
    setExpenseMSMEReport(state, action) {
      state.expenseMSMEReport = action.payload;
    },
    setPurchaseMSMEReport(state, action) {
      state.purchaseMSMEReport = action.payload;
    },
    setDeliveryTotalProjectCost(state, action) {
      state.deliveryTotalProjectCost = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setDeliveryChallanReportData,
  setTaxReportReturnPeriod,
  setExpanadAll1,
  setTab,
  setNetTrial,
  setGrossTrial,
  setExtendedTrial,
  setPrintDate,
  setlossSales,
  setlossPurchase,
  setPurchaseData,
  setSaleData,
  setTradingProfitLoss,
  setLiabilities,
  setAssets,
  setExpanadAll,
  setBalanceSheet,
  setTaxReport,
  setSalecustomer,
  setSaleItem,
  setSaleLocation,
  setCustomerBalances,
  setAging,
  setAgingDetails,
  setInvoiceDetails,
  setSundryReport,
  setEstimationReport,
  setInvoiceDetailReport,
  setPayableInvoiceReport,
  setExpenseNatureReport,
  setExpenseByBalance,
  setExpenseByLocation,
  setExpenseLedgerPerson,
  setPurchaseByVendor,
  setPurchaseByItem,
  setPurchaseByLocation,
  setFixedAssetsReport,
  setFixedAssetsPartyReport,
  setFixedAssetsBalanceReport,
  // Payment
  setPayment2Register,
  setPayment2Unregister,
  setPaymentByMSME,
  setPaymentViolation43B,
  setPaymentByLocation,
  setAdjustedPayment,
  setAdvancedPayment,
  setPayment2Employee,
  setUnAdjustedPayment,
  setSalesB2BReport,
  setSalesB2CReport,
  setSalesRCMReport,
  setSaleByProductByCustomer,
  setSaleByProductByService,
  setSalesMSMEReport,
  setExpenseMSMEReport,
  setPurchaseMSMEReport,
  setDeliveryTotalProjectCost
} = slice.actions;


export const getTotalProjectCost = (sd, ed, p, r, s,l) => async (dispatch) => {
  try {
    await axios
      .get(`/total_project_cost?location=${l}&start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setDeliveryTotalProjectCost(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTrackLocationWise = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/Invent_track_locationWise?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setDeliveryChallanReportData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTrackVendorWise = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/location_track_vendorwise?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setDeliveryChallanReportData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTrackInventoryLocation = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/tracking_invetroy_location?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setDeliveryChallanReportData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAgingSummary = (ed) => async (dispatch) => {
  try {
    await axios.get(`/aging_summary?end=${ed}`).then((response) => dispatch(setAging(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getCustomerBalances = (ed) => async (dispatch) => {
  try {
    await axios.get(`/Customer_Balances?end=${ed}`).then((response) => dispatch(setCustomerBalances(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSalesByItems = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SaleByItems?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSaleItem(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSaleByLocation = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SaleByLocation?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSaleLocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getNetTrial = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/net_trial_balance?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setNetTrial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGrossTrial = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/gross_trial_balance?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGrossTrial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExtendendTrial = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/extended_trial_balance?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setExtendedTrial(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLossSales = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/profit_loss_sale?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setlossSales(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLossPurchase = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/profit_loss_purcase?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setlossPurchase(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchaseData = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/purchase_data?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setPurchaseData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSaleData = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/sale_data?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setSaleData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTradingProfitLoss = (sd) => async (dispatch) => {
  try {
    await axios
      .get(`/trading_profit_loss?date_range=${sd}`)
      .then((response) => dispatch(setTradingProfitLoss(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLiabilities = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/get_liabilities?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setLiabilities(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAssets = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/get_assets?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setAssets(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBalanceSheet = (sd) => async (dispatch) => {
  try {
    await axios.get(`/balanceSheet?date_range=${sd}`).then((response) => dispatch(setBalanceSheet(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTaxReport = (filterStartDate) => async (dispatch) => {
  try {
    await axios
      .get(`/dashboard/taxReport?ret_period=${filterStartDate}`)
      .then((response) => dispatch(setTaxReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getsaleCustomer = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SaleByCustomer?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSalecustomer(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAgingDetails = (ed) => async (dispatch) => {
  try {
    await axios.get(`/ARAgingDetails?end=${ed}`).then((response) => dispatch(setAgingDetails(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceDetails = (ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/InvoiceDetailsReport?end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setInvoiceDetails(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSundryReport = (ed) => async (dispatch) => {
  try {
    await axios.get(`/SundryReport?end=${ed}`).then((response) => dispatch(setSundryReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getEstimationReport = (ed) => async (dispatch) => {
  try {
    await axios.get(`/EstimationReport?end=${ed}`).then((response) => dispatch(setEstimationReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getInvoiceDetailReport = (ed) => async (dispatch) => {
  try {
    await axios
      .get(`/InvoiceDetailsReport?end=${ed}`)
      .then((response) => dispatch(setInvoiceDetailReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPayableInvoiceReport = (ed) => async (dispatch) => {
  try {
    await axios
      .get(`/PayableInvoiceReport?end=${ed}`)
      .then((response) => dispatch(setPayableInvoiceReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExpenseNatureReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/ExpenseNatureReport?start=${sd}&end=${ed}&page=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExpenseNatureReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExpenseByBalance = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/ExpenseByBalance?start=${sd}&end=${ed}&page=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExpenseByBalance(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExpenseByLocation = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/ExpenseByLocation?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExpenseByLocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExpenseLedgerPerson = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/ExpenseLedgerPerson?start=${sd}&end=${ed}&page=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExpenseLedgerPerson(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPurchaseByVendor = (sd, ed, p, r) => async (dispatch) => {
  try {
    await axios
      .get(`/PurchaseByVendor?start=${sd}&end=${ed}&pagination=${p}&records=${r}`)
      .then((response) => dispatch(setPurchaseByVendor(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPurchaseByItem = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/PurchaseByItem?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPurchaseByItem(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPurchaseByLocation = (sd, ed, p, r) => async (dispatch) => {
  try {
    await axios
      .get(`/PurchaseByLocation?start=${sd}&end=${ed}&pagination=${p}&records=${r}`)
      .then((response) => dispatch(setPurchaseByLocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixedAssetsReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/FixedAssetsReport?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setFixedAssetsReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getFixedAssetsPartyReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/FixedAssetsPartyReport?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setFixedAssetsPartyReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFixedAssetsBalanceReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/FixedAssetsBalance_Report?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setFixedAssetsBalanceReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// payment

export const getPayment2Register = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/Payment2RegisterPerson?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPayment2Register(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
// Payment2UnregisterPerson?start=2022-11-11&end=2026-11-11&pagination=1&records=10
export const getPayment2Unregister = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/Payment2UnregisterPerson?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPayment2Unregister(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPaymentByMSME = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/PaymentBy_MSME?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPaymentByMSME(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPaymentViolation43B = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/PaymentViolation_of43B?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPaymentViolation43B(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPaymentByLocation = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/PaymentByLocation?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPaymentByLocation(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getAdjustedPayment = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/AdjustedPayment?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setAdjustedPayment(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getAdvancedPayment = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/AdvancedPayment?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setAdvancedPayment(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPayment2Employee = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/Payment2Employee?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPayment2Employee(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getUnAdjustedPayment = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/UnAdjustedPayment?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setUnAdjustedPayment(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesB2BReport = (sd, ed, t, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SalesB2B_B2CReport?start=${sd}&end=${ed}&type_=${t}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSalesB2BReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesB2CReport = (sd, ed, t, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SalesB2B_B2CReport?start=${sd}&end=${ed}&type_=${t}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSalesB2CReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesRCMReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SalesRCMReport?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSalesRCMReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSaleByProductByCustomer = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SaleByProduct_byCustomer?start=${sd}&end=${ed}&page=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSaleByProductByCustomer(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSaleByProductByService = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SaleByProduct_byService?start=${sd}&end=${ed}&page=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSaleByProductByService(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesMSMEReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/SalesMSMEReport?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setSalesMSMEReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getExpenseMSMEReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/ExpenseMSMEReport?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setExpenseMSMEReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getPurchaseMSMEReport = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    await axios
      .get(`/PurchaseMSMEReport?start=${sd}&end=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setPurchaseMSMEReport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
