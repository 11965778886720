import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  gstr1ViewInvoice: [],
  filterGstr1ViewInvoice: [],
  gstr1PrepareData: null,
  hsnSummary: [],
  gst2AData: [],
  tab: 'Input as per GST2A',
  notInbook: [],
  notIn2a: [],
  asPerbook: [],
  gst2BData: [],
  gst2aSummary: null,
  gst2bSummary: null,
  asperbooksSummary: null,
  isLoading: true,

  Gstr1Tab: 'B2B sales as per Portal',

  Gstr1B2Bsales: [],
  Gstr1B2BsalesSummary: [],
  Gstr1Exp: [],
  Gstr1expSummary: [],
  Gstr1credit: [],
  Gstr1creditSummary: [],
  Gstr1BillofSupply: [],
  Gstr1BillofSupplySummary: [],
  Gstr1RetailCredit: [],
  Gstr1RetailCreditSummary: [],
  Gstr1RetailSales: [],
  Gstr1RetailSalesSummary: [],
  // gstr1 recon as per books
  Gstr1HeaderTab: 'As Per Portal',
  Gstr1BooksTab: 'B2B sales as per Books',
  Gstr1B2BsalesBooks: [],
  Gstr1B2BsalesBooksSummary: [],
  Gstr1creditBooks: [],
  Gstr1creditBooksSummary: [],
  Gstr1ExpBooks: [],
  Gstr1expBooksSummary: [],
  Gstr1RetailSalesBooks: [],
  Gstr1RetailSalesBooksSummary: [],
  Gstr1RetailCreditBooks: [],
  Gstr1RetailCreditBooksSummary: [],
  Gstr1BillofSupplyBooks: [],
  Gstr1BillofSupplyBooksSummary: [],
  // gstr1 recon Not in Portal
  Gstr1NotinPortalTab: 'B2B sales Not in Portal',
  Gstr1B2BsalesNotinPortal: [],
  Gstr1B2BsalesNotinPortalSummary: [],
  Gstr1creditNotinPortal: [],
  Gstr1creditNotinPortalSummary: [],
  Gstr1ExpNotinPortal: [],
  Gstr1expNotinPortalSummary: [],
  Gstr1RetailSalesNotinPortal: [],
  Gstr1RetailSalesNotinPortalSummary: [],
  Gstr1RetailCreditNotinPortal: [],
  Gstr1RetailCreditNotinPortalSummary: [],
  Gstr1BillofSupplyNotinPortal: [],
  Gstr1BillofSupplyNotinPortalSummary: [],
  // gstr1 recon Not in Books
  Gstr1NotinBooksTab: 'B2B sales Not in Books',
  Gstr1B2BsalesNotinBooks: [],
  Gstr1B2BsalesNotinBooksSummary: [],
  Gstr1creditNotinBooks: [],
  Gstr1creditNotinBooksSummary: [],
  Gstr1ExpNotinBooks: [],
  Gstr1expNotinBooksSummary: [],
  Gstr1RetailSalesNotinBooks: [],
  Gstr1RetailSalesNotinBooksSummary: [],
  Gstr1RetailCreditNotinBooks: [],
  Gstr1RetailCreditNotinBooksSummary: [],
  Gstr1BillofSupplyNotinBooks: [],
  Gstr1BillofSupplyNotinBooksSummary: [],
};

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    setGstr1ViewInvoice(state, action) {
      state.gstr1ViewInvoice = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setFilterGstr1ViewInvoice(state, action) {
      state.filterGstr1ViewInvoice = action.payload;
    },
    setGstr1PrepareData(state, action) {
      state.gstr1PrepareData = action.payload;
    },
    setHsnSummary(state, action) {
      state.hsnSummary = action.payload;
    },
    setGst2AData(state, action) {
      state.gst2AData = action.payload;
    },
    setGst2BData(state, action) {
      state.gst2BData = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setNotinbook(state, action) {
      state.notInbook = action.payload;
    },
    setNotin2a(state, action) {
      state.notIn2a = action.payload;
    },
    setAsperbook(state, action) {
      state.asPerbook = action.payload;
    },
    setGst2aSummary(state, action) {
      state.gst2aSummary = action.payload;
    },
    setGst2bSummary(state, action) {
      state.gst2bSummary = action.payload;
    },
    setAsperbooksSummary(state, action) {
      state.asperbooksSummary = action.payload;
    },

    setGstr1Tab(state, action) {
      state.Gstr1Tab = action.payload;
    },
    setGstr1B2Bsales(state, action) {
      state.Gstr1B2Bsales = action.payload;
    },
    setGstr1B2BsalesSummary(state, action) {
      state.Gstr1B2BsalesSummary = action.payload;
    },
    setGstr1Exp(state, action) {
      state.Gstr1Exp = action.payload;
    },
    setGstr1expSummary(state, action) {
      state.Gstr1expSummary = action.payload;
    },
    setGstr1credit(state, action) {
      state.Gstr1credit = action.payload;
    },
    setGstr1creditSummary(state, action) {
      state.Gstr1creditSummary = action.payload;
    },
    setGstr1BillofSupply(state, action) {
      state.Gstr1BillofSupply = action.payload;
    },
    setGstr1BillofSupplySummary(state, action) {
      state.Gstr1BillofSupplySummary = action.payload;
    },
    setGstr1RetailCredit(state, action) {
      state.Gstr1RetailCredit = action.payload;
    },
    setGstr1RetailCreditSummary(state, action) {
      state.Gstr1RetailCreditSummary = action.payload;
    },
    setGstr1RetailSales(state, action) {
      state.Gstr1RetailSales = action.payload;
    },
    setGstr1RetailSalesSummary(state, action) {
      state.Gstr1RetailSalesSummary = action.payload;
    },
    //  books
    setGstr1HeaderTab(state, action) {
      state.Gstr1HeaderTab = action.payload;
    },
    setGstr1BooksTab(state, action) {
      state.Gstr1BooksTab = action.payload;
    },

    setGstr1B2BsalesBooks(state, action) {
      state.Gstr1B2BsalesBooks = action.payload;
    },
    setGstr1B2BsalesBooksSummary(state, action) {
      state.Gstr1B2BsalesBooksSummary = action.payload;
    },
    setGstr1creditBooks(state, action) {
      state.Gstr1creditBooks = action.payload;
    },
    setGstr1creditBooksSummary(state, action) {
      state.Gstr1creditBooksSummary = action.payload;
    },
    setGstr1ExpBooks(state, action) {
      state.Gstr1ExpBooks = action.payload;
    },
    setGstr1expBooksSummary(state, action) {
      state.Gstr1expBooksSummary = action.payload;
    },
    setGstr1RetailSalesBooks(state, action) {
      state.Gstr1RetailSalesBooks = action.payload;
    },
    setGstr1RetailSalesBooksSummary(state, action) {
      state.Gstr1RetailSalesBooksSummary = action.payload;
    },
    setGstr1RetailCreditBooks(state, action) {
      state.Gstr1RetailCreditBooks = action.payload;
    },
    setGstr1RetailCreditBooksSummary(state, action) {
      state.Gstr1RetailCreditBooksSummary = action.payload;
    },
    setGstr1BillofSupplyBooks(state, action) {
      state.Gstr1BillofSupplyBooks = action.payload;
    },
    setGstr1BillofSupplyBooksSummary(state, action) {
      state.Gstr1BillofSupplyBooksSummary = action.payload;
    },

    // Not in Portal
    setGstr1NotinPortalTab(state, action) {
      state.Gstr1NotinPortalTab = action.payload;
    },
    setGstr1B2BsalesNotinPortal(state, action) {
      state.Gstr1B2BsalesNotinPortal = action.payload;
    },
    setGstr1B2BsalesNotinPortalSummary(state, action) {
      state.Gstr1B2BsalesNotinPortalSummary = action.payload;
    },
    setGstr1creditNotinPortal(state, action) {
      state.Gstr1creditNotinPortal = action.payload;
    },
    setGstr1creditNotinPortalSummary(state, action) {
      state.Gstr1creditNotinPortalSummary = action.payload;
    },
    setGstr1ExpNotinPortal(state, action) {
      state.Gstr1ExpNotinPortal = action.payload;
    },
    setGstr1expNotinPortalSummary(state, action) {
      state.Gstr1expNotinPortalSummary = action.payload;
    },
    setGstr1RetailSalesNotinPortal(state, action) {
      state.Gstr1RetailSalesNotinPortal = action.payload;
    },
    setGstr1RetailSalesNotinPortalSummary(state, action) {
      state.Gstr1RetailSalesNotinPortalSummary = action.payload;
    },
    setGstr1RetailCreditNotinPortal(state, action) {
      state.Gstr1RetailCreditNotinPortal = action.payload;
    },
    setGstr1RetailCreditNotinPortalSummary(state, action) {
      state.Gstr1RetailCreditNotinPortalSummary = action.payload;
    },
    setGstr1BillofSupplyNotinPortal(state, action) {
      state.Gstr1BillofSupplyNotinPortal = action.payload;
    },
    setGstr1BillofSupplyNotinPortalSummary(state, action) {
      state.Gstr1BillofSupplyNotinPortalSummary = action.payload;
    },

    // Not in Books
    setGstr1NotinBookslTab(state, action) {
      state.Gstr1NotinBooksTab = action.payload;
    },
    setGstr1B2BsalesNotinBooks(state, action) {
      state.Gstr1B2BsalesNotinBooks = action.payload;
    },
    setGstr1B2BsalesNotinBooksSummary(state, action) {
      state.Gstr1B2BsalesNotinBooksSummary = action.payload;
    },
    setGstr1creditNotinBooks(state, action) {
      state.Gstr1creditNotinBooks = action.payload;
    },
    setGstr1creditNotinBooksSummary(state, action) {
      state.Gstr1creditNotinBooksSummary = action.payload;
    },
    setGstr1ExpNotinBooks(state, action) {
      state.Gstr1ExpNotinBooks = action.payload;
    },
    setGstr1expNotinBooksSummary(state, action) {
      state.Gstr1expNotinBooksSummary = action.payload;
    },
    setGstr1RetailSalesNotinBooks(state, action) {
      state.Gstr1RetailSalesNotinBooks = action.payload;
    },
    setGstr1RetailSalesNotinBooksSummary(state, action) {
      state.Gstr1RetailSalesNotinBooksSummary = action.payload;
    },
    setGstr1RetailCreditNotinBooks(state, action) {
      state.Gstr1RetailCreditNotinBooks = action.payload;
    },
    setGstr1RetailCreditNotinBooksSummary(state, action) {
      state.Gstr1RetailCreditNotinBooksSummary = action.payload;
    },
    setGstr1BillofSupplyNotinBooks(state, action) {
      state.Gstr1BillofSupplyNotinBooks = action.payload;
    },
    setGstr1BillofSupplyNotinBooksSummary(state, action) {
      state.Gstr1BillofSupplyNotinBooksSummary = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setGstr1ViewInvoice,
  setFilterGstr1ViewInvoice,
  setGstr1PrepareData,
  setHsnSummary,
  setGst2AData,
  setTab,
  setNotinbook,
  setNotin2a,
  setAsperbook,
  setGst2BData,
  setGst2aSummary,
  setGst2bSummary,
  setAsperbooksSummary,
  setIsLoading,

  setGstr1Tab,
  setGstr1B2Bsales,
  setGstr1B2BsalesSummary,
  setGstr1Exp,
  setGstr1expSummary,
  setGstr1credit,
  setGstr1creditSummary,
  setGstr1BillofSupply,
  setGstr1BillofSupplySummary,
  setGstr1RetailCredit,
  setGstr1RetailCreditSummary,
  setGstr1RetailSales,
  setGstr1RetailSalesSummary,
  // gstr1 recon as per books

  setGstr1HeaderTab,
  setGstr1BooksTab,
  setGstr1B2BsalesBooks,
  setGstr1B2BsalesBooksSummary,
  setGstr1creditBooks,
  setGstr1creditBooksSummary,
  setGstr1ExpBooks,
  setGstr1expBooksSummary,
  setGstr1RetailSalesBooks,
  setGstr1RetailSalesBooksSummary,
  setGstr1RetailCreditBooks,
  setGstr1RetailCreditBooksSummary,
  setGstr1BillofSupplyBooks,
  setGstr1BillofSupplyBooksSummary,

  // gstr1 recon Not in Portal
  setGstr1NotinPortalTab,
  setGstr1B2BsalesNotinPortal,
  setGstr1B2BsalesNotinPortalSummary,
  setGstr1creditNotinPortal,
  setGstr1creditNotinPortalSummary,
  setGstr1ExpNotinPortal,
  setGstr1expNotinPortalSummary,
  setGstr1RetailSalesNotinPortal,
  setGstr1RetailSalesNotinPortalSummary,
  setGstr1RetailCreditNotinPortal,
  setGstr1RetailCreditNotinPortalSummary,
  setGstr1BillofSupplyNotinPortal,
  setGstr1BillofSupplyNotinPortalSummary,

  // gstr1 recon Not in Books
  setGstr1NotinBookslTab,
  setGstr1B2BsalesNotinBooks,
  setGstr1B2BsalesNotinBooksSummary,
  setGstr1creditNotinBooks,
  setGstr1creditNotinBooksSummary,
  setGstr1ExpNotinBooks,
  setGstr1expNotinBooksSummary,
  setGstr1RetailSalesNotinBooks,
  setGstr1RetailSalesNotinBooksSummary,
  setGstr1RetailCreditNotinBooks,
  setGstr1RetailCreditNotinBooksSummary,
  setGstr1BillofSupplyNotinBooks,
  setGstr1BillofSupplyNotinBooksSummary,
} = slice.actions;

export const getGst2aSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/get2A?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGst2aSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGst2bSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/get2B?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGst2bSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAsperbooksSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/summary/AsPerBooks?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setAsperbooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAsperbook = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/dataAsPerBooks?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setAsperbook(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getNotIn2a = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`compare?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setNotin2a(response.data.notIn2A)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getNotInbooks = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`compare?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setNotinbook(response.data.notInBooks)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGstr1ViewInvoice = (date) => async (dispatch) => {
  try {
    axios.get(`/gstr1ViewInvoice?ret_period=${date}`).then((response) => dispatch(setGstr1ViewInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getFilterGstr1ViewInvoice = (date) => async (dispatch) => {
  try {
    axios
      .get(`/filterGstr1ViewInvoice?ret_period=${date}`)
      .then((response) => dispatch(setFilterGstr1ViewInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1PrepareData = (date) => async (dispatch) => {
  try {
    axios.get(`/gstr1PrepareData?ret_period=${date}`).then((response) => dispatch(setGstr1PrepareData(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getHsnSummary = (date) => async (dispatch) => {
  try {
    axios.get(`/hsnSummary?ret_period=${date}`).then((response) => dispatch(setHsnSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGst2A = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/get2AData?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setGst2AData(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGst2B = (sd, ed, p, r, s) => async (dispatch) => {
  try {
    axios
      .get(`/get2BData?start_date=${sd}&end_date=${ed}&pagination=${p}&records=${r}&search=${s}`)
      .then((response) => dispatch(setGst2BData(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));

    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2Bsales = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1B2bData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1B2Bsales(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2BsalesSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1b2b?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1B2BsalesSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1Exp = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1ExpData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1Exp(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1expSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1exp?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1expSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1credit = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1cdnrData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1credit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1cdnr?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1creditSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupply = () => async (dispatch) => {
  try {
    axios.get(`/getGstr1NilData`).then((response) => dispatch(setGstr1BillofSupply(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupplySummary = () => async (dispatch) => {
  try {
    axios.get(`/summary/nil`).then((response) => dispatch(setGstr1BillofSupplySummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCredit = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/getGstr1cdnurData?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1RetailCredit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1cdnur?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1RetailCreditSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1RetailSales = () => async (dispatch) => {
  try {
    axios.get(`/getGstr1B2cData`).then((response) => dispatch(setGstr1RetailSales(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/summary/gstr1b2c?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// gst R1 Recon  as per books

export const getGstr1B2BsalesBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1B2BsalesBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2BsalesBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1B2BsalesBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditNote_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1creditBooks(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditSummaryBooks = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditnot_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1creditBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1ExpBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/export_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1ExpBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1expBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_export_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1expBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesBooks = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_Sale_as_per_book?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_Sale_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/retail_creditNote_as_per_book?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1RetailCreditBooks(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_creditNote_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailCreditBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1BillofSupplyBooks = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/bos_as_per_book?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1BillofSupplyBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupplyBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/bos_as_per_book_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1BillofSupplyBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// gstr1 Not in Portal

export const getGstr1B2BsalesNotinPortal = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_NIP?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1B2BsalesNotinPortal(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2BsalesNotinPortalSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_NIP_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1B2BsalesNotinPortalSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditNotinPortal = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditNote_NIP?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1creditNotinPortal(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditSummaryNotinPortal = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditnot_NIP_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1creditNotinPortalSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1ExpNotinPortal = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/export_NIP?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1ExpNotinPortal(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1expNotinPortalSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_export_NIP_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1expNotinPortalSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesNotinPortal = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_Sale_NIP?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesNotinPortal(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesNotinPortalSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_Sale_NIP_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesNotinPortalSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditNotinPortal = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/retail_creditNote_NIP?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1RetailCreditNotinPortal(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditNotinPortalSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_creditNote_NIP_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailCreditNotinPortalSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1BillofSupplyNotinPortal = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/bos_NIP?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1BillofSupplyNotinPortal(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupplyNotinPortalSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/bos_as_per_book_summar?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1BillofSupplyNotinPortalSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// gstr1 Not in Books

export const getGstr1B2BsalesNotinBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_NIB?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1B2BsalesNotinBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1B2BsalesNotinBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_Sale_NIB_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1B2BsalesNotinBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditNotinBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditnote_NIB?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1creditNotinBooks(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1creditSummaryNotinBooks = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_creditnote_NIB_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1creditNotinBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1ExpNotinBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_export_NIB?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1ExpNotinBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1expNotinBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/b2b_export_NIB_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1expNotinBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesNotinBooks = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_Sale_NIB?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesNotinBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailSalesNotinBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_sale_NIB_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailSalesNotinBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditNotinBooks = (ed, sd, s, r, p) => async (dispatch) => {
  try {
    axios
      .get(`/retail_CreditNote_NIB?end=${ed}&start=${sd}&search=${s}&records=${r}&pagination=${p}`)
      .then((response) => dispatch(setGstr1RetailCreditNotinBooks(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getGstr1RetailCreditNotinBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/retail_CreditNote_NIB_summary?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1RetailCreditNotinBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getGstr1BillofSupplyNotinBooks = (sd) => async (dispatch) => {
  try {
    axios.get(`/bos_NIB?retperiod=${sd}`).then((response) => dispatch(setGstr1BillofSupplyNotinBooks(response.data)));
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return console.error(error.message);
  }
  return true;
};

export const getGstr1BillofSupplyNotinBooksSummary = (sd, ed) => async (dispatch) => {
  try {
    axios
      .get(`/bos_as_per_book_summar?start=${sd}&end=${ed}`)
      .then((response) => dispatch(setGstr1BillofSupplyNotinBooksSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
