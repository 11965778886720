// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  pricingPlan: '/pricing-plans',
  payment: '/payment',
  banking: '/banking',
  about: '/about-us',
  contact: '/contact-us',
  ReportAnIssue: '/report-an-issue',
  blog: '/blog',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  termsOfService: '/terms-of-services',
  PrivacyPolicy: '/privacy-policy',
  CancellationRefund: '/Cancellation-Refund-Policy',
  pricingPlanLink: '/pricingPlanLink',
  featuresGstBilling: '/featuresGstBilling',
  homeRegister: '/homeRegister',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  importdata: {
    root: path(ROOTS_DASHBOARD, '/importdata'),
    importForm: path(ROOTS_DASHBOARD, '/importdata/import-data-form'),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
    profileSetting: path(ROOTS_DASHBOARD, '/setting/profile-setting'),
    salesSetting: path(ROOTS_DASHBOARD, '/setting/sales-setting'),
    transportSetting: path(ROOTS_DASHBOARD, '/setting/transport-setting'),
    recieptPaymentSetting: path(ROOTS_DASHBOARD, '/setting/receipt-payment-setting'),
    purchaseSetting: path(ROOTS_DASHBOARD, '/setting/purchase-setting'),
    expenseSetting: path(ROOTS_DASHBOARD, '/setting/expense-setting'),
    jobProjectSetting: path(ROOTS_DASHBOARD, '/setting/job-project-setting'),
    bankingsetting: path(ROOTS_DASHBOARD, '/setting/banking-setting'),
    taxSetting: path(ROOTS_DASHBOARD, '/setting/tax-setting'),
    whatsUpSetting: path(ROOTS_DASHBOARD, '/setting/whatsup-setting'),
    emailSetting: path(ROOTS_DASHBOARD, '/setting/email-setting'),
    industriesSetting: path(ROOTS_DASHBOARD, '/setting/industries-setting'),
  },
  branches: {
    branch: path(ROOTS_DASHBOARD, '/branches/branch'),
    newBranch: path(ROOTS_DASHBOARD, '/branches/new-branch'),
  },
  barcode: {
    generateBarcode: path(ROOTS_DASHBOARD, '/barcode/generate-barcode'),
    // newBranch: path(ROOTS_DASHBOARD, '/branches/new-branch'),
  },

  masterData: {
    ledgerParty: path(ROOTS_DASHBOARD, '/master-data/ledger-party'),
    newLedgerParty: path(ROOTS_DASHBOARD, '/master-data/new-ledger-party'),
    editLedgerParty: path(ROOTS_DASHBOARD, '/master-data/edit-ledger-party'),
    ledgerDetails: path(ROOTS_DASHBOARD, '/master-data/ledger-details'),
    inventoryMaster: path(ROOTS_DASHBOARD, '/master-data/inventory-master'),
    newInventory: path(ROOTS_DASHBOARD, '/master-data/new-inventory-master'),
    editInventory: path(ROOTS_DASHBOARD, `/master-data/edit-inventory-master`),
    serviceMaster: path(ROOTS_DASHBOARD, '/master-data/service-master'),
    newServiceMaster: path(ROOTS_DASHBOARD, '/master-data/new-service-master'),
    editServiceMaster: path(ROOTS_DASHBOARD, '/master-data/edit-service-master'),
  },

  organisation: {
    transport: path(ROOTS_DASHBOARD, '/organisation/transport'),
    courier: path(ROOTS_DASHBOARD, '/organisation/Courier'),
    newTransport: path(ROOTS_DASHBOARD, '/organisation/new-transport'),
    newTransportForm: path(ROOTS_DASHBOARD, '/organisation/new-transport-form'),
    editTransportForm: path(ROOTS_DASHBOARD, '/organisation/edit-transport-form'),
    duplicateTransportForm: path(ROOTS_DASHBOARD, '/organisation/duplicate-transport-form'),
  },

  users: {
    root: path(ROOTS_DASHBOARD, '/users/user'),
    newUser: path(ROOTS_DASHBOARD, '/users/new-user'),
    editUser: path(ROOTS_DASHBOARD, `/users/edit-user`),
  },
  salesPanel: {
    root: path(ROOTS_DASHBOARD, '/sales-panel/user'),
    newUser: path(ROOTS_DASHBOARD, '/sales-panel/new-user'),
    editUser: path(ROOTS_DASHBOARD, `/sales-panel/edit-user`),
  },

  sales: {
    invoiceList: path(ROOTS_DASHBOARD, '/sales/invoice-list'),
    newInvoice: path(ROOTS_DASHBOARD, '/sales/new-invoice'),
    editInvoice: path(ROOTS_DASHBOARD, '/sales/edit-invoice'),
    viewInvoice: path(ROOTS_DASHBOARD, '/sales/view-invoice'),
    duplicateInvoice: path(ROOTS_DASHBOARD, '/sales/duplicate-invoice'),
    newBill: path(ROOTS_DASHBOARD, '/sales/new-bill'),
    editBill: path(ROOTS_DASHBOARD, '/sales/edit-bill'),
    viewBill: path(ROOTS_DASHBOARD, '/sales/view-bill'),
    duplicateBill: path(ROOTS_DASHBOARD, '/sales/duplicate-bill'),
    newExport: path(ROOTS_DASHBOARD, '/sales/new-export-invoice'),
    editExport: path(ROOTS_DASHBOARD, '/sales/edit-export-invoice'),
    viewExport: path(ROOTS_DASHBOARD, '/sales/view-export-invoice'),
    duplicateExport: path(ROOTS_DASHBOARD, '/sales/duplicate-export-invoice'),
    newCommercial: path(ROOTS_DASHBOARD, '/sales/new-commercial-invoice'),
    editCommercial: path(ROOTS_DASHBOARD, '/sales/edit-commercial-invoice'),
    viewCommercial: path(ROOTS_DASHBOARD, '/sales/view-commercial-invoice'),
    duplicateCommercial: path(ROOTS_DASHBOARD, '/sales/duplicate-commercial-invoice'),
    delivery: path(ROOTS_DASHBOARD, '/sales/delivery'),
    editDelivery: path(ROOTS_DASHBOARD, '/sales/edit-delivery'),
    viewDelivery: path(ROOTS_DASHBOARD, '/sales/view-delivery'),
    duplicateDelivery: path(ROOTS_DASHBOARD, '/sales/duplicate-delivery'),
    creditNote: path(ROOTS_DASHBOARD, '/sales/credit-note'),
    editCreditNote: path(ROOTS_DASHBOARD, '/sales/change-credit-note'),
    viewCreditNote: path(ROOTS_DASHBOARD, '/sales/view-credit-note'),
    duplicateCreditNote: path(ROOTS_DASHBOARD, '/sales/duplicate-credit-note'),
    salesOrder: path(ROOTS_DASHBOARD, '/sales/sales-order'),
    editSalesOrder: path(ROOTS_DASHBOARD, '/sales/edit-sales-order'),
    viewSalesOrder: path(ROOTS_DASHBOARD, '/sales/view-sales-order'),
    duplicateSalesOrder: path(ROOTS_DASHBOARD, '/sales/duplicate-sales-order'),
    performaInvoice: path(ROOTS_DASHBOARD, '/sales/performa-invoice'),
    editPerformaInvoice: path(ROOTS_DASHBOARD, '/sales/edit-performa-invoice'),
    viewPerformaInvoice: path(ROOTS_DASHBOARD, '/sales/view-performa-invoice'),
    duplicatePerformaInvoice: path(ROOTS_DASHBOARD, '/sales/duplicate-performa-invoice'),
    estimation: path(ROOTS_DASHBOARD, '/sales/estimation'),
    editEstimation: path(ROOTS_DASHBOARD, '/sales/edit-estimation'),
    viewEstimation: path(ROOTS_DASHBOARD, '/sales/view-estimation'),
    duplicateEstimation: path(ROOTS_DASHBOARD, '/sales/duplicate-estimation'),
    debitNote: path(ROOTS_DASHBOARD, '/sales/debit-note'),
    editDebitNote: path(ROOTS_DASHBOARD, '/sales/edit-debit-note'),
    duplicateDebitNote: path(ROOTS_DASHBOARD, '/sales/duplicate-debit-note'),
    viewDebitNote: path(ROOTS_DASHBOARD, '/sales/view-debit-note'),
    newDebitNote: path(ROOTS_DASHBOARD, '/sales/new-debit-note'),
    otherIncome: path(ROOTS_DASHBOARD, '/sales/other-income'),
    editOtherIncome: path(ROOTS_DASHBOARD, '/sales/edit-other-income'),
    viewOtherIncome: path(ROOTS_DASHBOARD, '/sales/view-other-income'),
    duplicateOtherIncome: path(ROOTS_DASHBOARD, '/sales/duplicate-other-income'),
    newOtherIncome: path(ROOTS_DASHBOARD, '/sales/new-other-income'),
    stockTransfer: path(ROOTS_DASHBOARD, '/sales/stock-transfer'),
    editStockTransfer: path(ROOTS_DASHBOARD, '/sales/edit-stock-transfer'),
    viewStockTransfer: path(ROOTS_DASHBOARD, '/sales/view-stock-transfer'),
    duplicateStockTransfer: path(ROOTS_DASHBOARD, '/sales/duplicate-stock-transfer'),
    newStockTransfer: path(ROOTS_DASHBOARD, '/sales/new-stock-transfer'),
  },
  reciept: {
    root: path(ROOTS_DASHBOARD, '/reciept'),
    newReciept: path(ROOTS_DASHBOARD, '/reciept/new-reciept'),
    editReciept: path(ROOTS_DASHBOARD, '/reciept/edit-reciept'),
    viewReciept: path(ROOTS_DASHBOARD, '/reciept/view-reciept'),
  },
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment'),
    newExpense: path(ROOTS_DASHBOARD, '/payment/new-expense'),
    editExpense: path(ROOTS_DASHBOARD, '/payment/edit-expense'),
    viewExpense: path(ROOTS_DASHBOARD, '/payment/view-expense'),
    duplicateExpense: path(ROOTS_DASHBOARD, '/payment/duplicate-expense'),

    newPayment: path(ROOTS_DASHBOARD, '/payment/new-payment'),
    editPayment: path(ROOTS_DASHBOARD, '/payment/edit-payment'),
    viewPayment: path(ROOTS_DASHBOARD, '/payment/view-payment'),
  },
  expenseOrJournal: {
    root: path(ROOTS_DASHBOARD, '/expense-journal'),
    // newExpenseOrJournal: path(ROOTS_DASHBOARD, '/expense-journal/new-expense-journal'),
    // editExpenseOrJournal: path(ROOTS_DASHBOARD, '/expense-journal/edit-expense-journal'),
    // viewExpenseOrJournal: path(ROOTS_DASHBOARD, '/expense-journal/view-expense-journal'),
    // duplicateExpenseOrJournal: path(ROOTS_DASHBOARD, '/expense-journal/duplicate-expense-journal'),
    Expense: path(ROOTS_DASHBOARD, '/expense-journal/new-expenses'),
    editExpense: path(ROOTS_DASHBOARD, '/expense-journal/edit-expenses'),
    viewExpense: path(ROOTS_DASHBOARD, '/expense-journal/view-expenses'),
    duplicateExpense: path(ROOTS_DASHBOARD, '/expense-journal/duplicate-expenses'),

    Journal: path(ROOTS_DASHBOARD, '/expense-journal/new-expense-journals'),
    editJournal: path(ROOTS_DASHBOARD, '/expense-journal/edit-expense-journals'),
    viewJournal: path(ROOTS_DASHBOARD, '/expense-journal/view-expense-journals'),
    duplicateJournal: path(ROOTS_DASHBOARD, '/expense-journal/duplicate-expense-journals'),

    Fixassests: path(ROOTS_DASHBOARD, '/expense-journal/new-expense-Fixassests'),
    editFixassests: path(ROOTS_DASHBOARD, '/expense-journal/edit-expense-Fixassests'),
    viewFixassests: path(ROOTS_DASHBOARD, '/expense-journal/view-expense-Fixassests'),
    duplicateFixassests: path(ROOTS_DASHBOARD, '/expense-journal/duplicate-expense-Fixassests'),
  },
  purchase: {
    root: path(ROOTS_DASHBOARD, '/purchase'),
    newPurchase: path(ROOTS_DASHBOARD, '/purchase/new-purchase'),
    editPurchase: path(ROOTS_DASHBOARD, '/purchase/edit-purchase'),
    duplicatePurchase: path(ROOTS_DASHBOARD, '/purchase/duplicate-purchase'),
    viewPurchase: path(ROOTS_DASHBOARD, '/purchase/view-purchase'),
    purchaseBill: path(ROOTS_DASHBOARD, '/purchase/purchase-bill'),
    editPurchaseBill: path(ROOTS_DASHBOARD, '/purchase/edit-purchase-bill'),
    duplicatePurchaseBill: path(ROOTS_DASHBOARD, '/purchase/duplicate-purchase-bill'),
    viewPurchaseBill: path(ROOTS_DASHBOARD, '/purchase/view-purchase-bill'),
    purchaseExport: path(ROOTS_DASHBOARD, '/purchase/purchase-export'),
    editPurchaseExport: path(ROOTS_DASHBOARD, '/purchase/edit-purchase-export'),
    duplicatePurchaseExport: path(ROOTS_DASHBOARD, '/purchase/duplicate-purchase-export'),
    viewPurchaseExport: path(ROOTS_DASHBOARD, '/purchase/view-purchase-export'),
    purchaseOrder: path(ROOTS_DASHBOARD, '/purchase/purchase-order'),
    editpurchaseOrder: path(ROOTS_DASHBOARD, '/purchase/edit-purchase-order'),
    duplicatePurchaseOrder: path(ROOTS_DASHBOARD, '/purchase/duplicate-purchase-order'),
    viewPurchaseOrder: path(ROOTS_DASHBOARD, '/purchase/view-purchase-order'),

    InwardDeliveryChallan: path(ROOTS_DASHBOARD, '/purchase/InwardDeliveryChallan'),
    editInwardDeliveryChallan: path(ROOTS_DASHBOARD, '/purchase/edit-InwardDeliveryChallan'),
    duplicateInwardDeliveryChallan: path(ROOTS_DASHBOARD, '/purchase/duplicate-InwardDeliveryChallan'),
    viewInwardDeliveryChallan: path(ROOTS_DASHBOARD, '/purchase/view-InwardDeliveryChallan'),
  },
  jobProject: {
    root: path(ROOTS_DASHBOARD, '/job-project'),
    newJob: path(ROOTS_DASHBOARD, '/job-project/new-job'),
    newProject: path(ROOTS_DASHBOARD, '/job-project/new-project'),
    editJobProject: path(ROOTS_DASHBOARD, '/job-project/edit-Job-Project'),
    viewJobProject: path(ROOTS_DASHBOARD, '/job-project/view-Job-Project'),
    duplicateJobProject: path(ROOTS_DASHBOARD, '/job-project/duplicate-Job-Project'),

    view: (id) => path(ROOTS_DASHBOARD, `/job-project/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/job-project/${id}/edit`),
  },
  ecommerceOnlineSales: {
    root: path(ROOTS_DASHBOARD, '/ecommerce-OnlineSales'),
    ecomFile: path(ROOTS_DASHBOARD, '/ecommerce-OnlineSales/ecom-file'),
    // editEcommerceOnlineSales: path(ROOTS_DASHBOARD,'/ecommerce-OnlineSales/edit-ecom-file')
  },
  tax: {
    gst: path(ROOTS_DASHBOARD, '/tax/gst'),
    gstr1: path(ROOTS_DASHBOARD, '/tax/gstr-1'),
    gstr1recon: path(ROOTS_DASHBOARD, '/tax/gstr-1-recon'),
    downloadGstr1: path(ROOTS_DASHBOARD, '/tax/download-gstr-1'),
    gstr9: path(ROOTS_DASHBOARD, '/tax/gstr-9'),
    gstr2a: path(ROOTS_DASHBOARD, '/tax/gstr-2a'),
    downloaddatafromgst: path(ROOTS_DASHBOARD, '/tax/download-gst-data'),
    gstr3b: path(ROOTS_DASHBOARD, '/tax/gstr-3b'),
    tablegst3: path(ROOTS_DASHBOARD, '/tax/table-gstr3'),
    tablegst2: path(ROOTS_DASHBOARD, '/tax/table-gstr2'),
    pmt6: path(ROOTS_DASHBOARD, '/tax/pmt-6'),
    itc04: path(ROOTS_DASHBOARD, '/tax/ITC-04'),
    tds: path(ROOTS_DASHBOARD, '/tax/tds'),
    tcs: path(ROOTS_DASHBOARD, '/tax/tcs'),
    incomeTax: path(ROOTS_DASHBOARD, '/tax/incomeTax'),
    vat: path(ROOTS_DASHBOARD, '/tax/vat'),
    duty: path(ROOTS_DASHBOARD, '/tax/duty'),
    // ReportAlltable (PurchaseTable)
    PurchasebyVendor: path(ROOTS_DASHBOARD, '/tax/PurchasebyVendor'),
    PurchasebyLocation: path(ROOTS_DASHBOARD, '/tax/PurchasebyLocation'),
    PurchasebyPerson: path(ROOTS_DASHBOARD, '/tax/PurchasebyPerson'),
    PurchasebyItem: path(ROOTS_DASHBOARD, '/tax/PurchasebyItem'),
    PurchaseMSMEReport: path(ROOTS_DASHBOARD, '/tax/PurchaseMSME'),

    // salesTable
    Salecustomer: path(ROOTS_DASHBOARD, '/tax/Salecustomer'),
    Saleperson: path(ROOTS_DASHBOARD, '/tax/Saleperson'),
    SaleItem: path(ROOTS_DASHBOARD, '/tax/SaleItem'),
    SalesB2B: path(ROOTS_DASHBOARD, '/tax/SalesB2B'),
    SalesB2C: path(ROOTS_DASHBOARD, '/tax/SalesB2C'),
    SalesRCM: path(ROOTS_DASHBOARD, '/tax/SalesRCM'),
    SalesByProductByCustomer: path(ROOTS_DASHBOARD, '/tax/SalesByProductByCustomer'),
    SalesByProductByService: path(ROOTS_DASHBOARD, '/tax/SalesByProductByService'),
    SalesMSMEReport: path(ROOTS_DASHBOARD, '/tax/SalesMSME'),

    // FixedTable
    FixedAsset: path(ROOTS_DASHBOARD, '/tax/FixedAsset'),
    FixedParty: path(ROOTS_DASHBOARD, '/tax/FixedParty'),
    FixedBalance: path(ROOTS_DASHBOARD, '/tax/FixedBalance'),
    // Payable
    PayableSundry: path(ROOTS_DASHBOARD, '/tax/PayableSundry'),
    PayableDetail: path(ROOTS_DASHBOARD, '/tax/PayableDetail'),
    PayableInvoice: path(ROOTS_DASHBOARD, '/tax/PayableInvoice'),
    PayableSummary: path(ROOTS_DASHBOARD, '/tax/PayableSummary'),
    // Payment
    Payment2RegisterPerson: path(ROOTS_DASHBOARD, '/tax/Payment2Register'),
    Payment2UnregisterPerson: path(ROOTS_DASHBOARD, '/tax/Payment2Unregister'),
    PaymentViolationof43B: path(ROOTS_DASHBOARD, '/tax/PaymentViolation43B'),
    PaymentByMSME: path(ROOTS_DASHBOARD, '/tax/PaymentByMSME'),
    PaymentByLocation: path(ROOTS_DASHBOARD, '/tax/PaymentByLocation'),
    AdjustedPayment: path(ROOTS_DASHBOARD, '/tax/AdjustedPayment'),
    AdvancedPayment: path(ROOTS_DASHBOARD, '/tax/AdvancedPayment'),
    Payment2Employee: path(ROOTS_DASHBOARD, '/tax/Payment2Employee'),
    UnAdjustedPayment: path(ROOTS_DASHBOARD, '/tax/UnAdjustedPayment'),

    // Expense
    ExpenseNature: path(ROOTS_DASHBOARD, '/tax/ExpenseNature'),
    ExpenseBalance: path(ROOTS_DASHBOARD, '/tax/ExpenseBalance'),
    ExpenseLocation: path(ROOTS_DASHBOARD, '/tax/ExpenseLocation'),
    ExpensePerson: path(ROOTS_DASHBOARD, '/tax/ExpensePerson'),
    ExpenseMSMEReport: path(ROOTS_DASHBOARD, '/tax/ExpenseMSME'),

    // Receivable
    ReceivableBalance: path(ROOTS_DASHBOARD, '/tax/ReceivableBalance'),
    ARDetail: path(ROOTS_DASHBOARD, '/tax/ARDetail'),
    ARSummary: path(ROOTS_DASHBOARD, '/tax/ARSummary'),
    ReceivableInvoice: path(ROOTS_DASHBOARD, '/tax/ReceivableInvoice'),
    ReceivableRetainer: path(ROOTS_DASHBOARD, '/tax/ReceivableRetainer'),
    ReceivableEstimate: path(ROOTS_DASHBOARD, '/tax/ReceivableEstimate'),
    ReceivableBad: path(ROOTS_DASHBOARD, '/tax/ReceivableBad'),
    ReceivableBank: path(ROOTS_DASHBOARD, '/tax/ReceivableBank'),
    ReceivableCustomer: path(ROOTS_DASHBOARD, '/tax/ReceivableCustomer'),
    ReceivableSummary: path(ROOTS_DASHBOARD, '/tax/ReceivableSummary'),
    ReceivableDetail: path(ROOTS_DASHBOARD, '/tax/ReceivableDetail'),
  },

  report: {
    root: path(ROOTS_DASHBOARD, '/report'),
    financial: path(ROOTS_DASHBOARD, '/report/financial'),

    history: path(ROOTS_DASHBOARD, '/report/history'),
    GstTable: path(ROOTS_DASHBOARD, '/report/GstTable'),
    OtherReport: path(ROOTS_DASHBOARD, '/report/OtherReport'),
    trialBalance: path(ROOTS_DASHBOARD, '/report/trial-balance'),
    trailView: path(ROOTS_DASHBOARD, '/report/trial-view'),
    profitAndLoss: path(ROOTS_DASHBOARD, '/report/profit-loss'),
    balancesheet: path(ROOTS_DASHBOARD, '/report/balance-sheet'),
    cashflow: path(ROOTS_DASHBOARD, '/report/cash-flow'),
  },

  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  hrEmployee: {
    root: path(ROOTS_DASHBOARD, '/employee-salary'),
    employeeSalary: path(ROOTS_DASHBOARD, '/employee-salary'),
    newEmployeeSalary: path(ROOTS_DASHBOARD, '/employee-salary/new-employee-salary'),
    editEmployeeSalary: path(ROOTS_DASHBOARD, '/employee-salary/edit-employee-salary'),
    formemployeeSalary: path(ROOTS_DASHBOARD, '/employee-salary/form-employee-salary'),
  },
  marketing: {
    root: path(ROOTS_DASHBOARD, '/marketing'),
  },
  banking: {
    root: path(ROOTS_DASHBOARD, '/banking'),
    newbankingtable: path(ROOTS_DASHBOARD, '/banking/newbankingtable'),
    bankingform: path(ROOTS_DASHBOARD, '/banking/bankingform'),
  },
  funding: {
    root: path(ROOTS_DASHBOARD, '/funding'),
  },
  virtual: {
    root: path(ROOTS_DASHBOARD, '/virtual'),
  },
  Pricing: {
    root: path(ROOTS_DASHBOARD, '/Pricing'),
  },
};
