import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import Offer from '../pages/Offer';

// import PrivacyPolicy from 'src/pages/dashboard/PrivacyPolicy';

// import PlanComparison from 'src/pages/PlanComparison';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    { path: 'privacy-policy', element: <PrivacyPolicy /> },
    { path: 'Cancellation-Refund-Policy', element: <CancellationRefund /> },
    { path: 'terms-of-services', element: <TermsofServices /> },
    { path: 'offer', element: <Offer /> },
    // { path: 'price', element: <Price /> },
    { path: 'payment/success', element: <PaymentSuccess /> },
    { path: 'payment/cancle', element: <PaymentCancle /> },
    { path: 'pricingPlanLink', element: <PricingPlanLink /> },
    { path: 'featuresGstBilling', element: <FeaturesGstBilling /> },
    { path: 'homeRegister', element: <HomeRegister /> },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },

        {
          path: 'setting',
          children: [
            { element: <Settings />, index: true },
            { path: 'profile-setting', element: <ProfileSetting /> },
            { path: 'sales-setting', element: <SalesSettings /> },
            { path: 'transport-setting', element: <TransportSettings /> },
            { path: 'purchase-setting', element: <PurchaseSettings /> },
            { path: 'receipt-payment-setting', element: <ReceiptSetting /> },
            { path: 'expense-setting', element: <ExpenseSetting /> },
            { path: 'job-project-setting', element: <JobProjectSetting /> },
            { path: 'tax-setting', element: <TaxSetting /> },
            { path: 'whatsup-setting', element: <WhatsUpSetting /> },
            { path: 'email-setting', element: <EmailSetting /> },
            { path: 'industries-setting', element: <IndustriesSetting /> },
          ],
        },

        {
          path: 'importdata',
          children: [{ path: 'import-data-form', element: <ImportForm /> }],
        },

        {
          path: 'branches',
          children: [
            {
              path: 'branch',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Branches />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-branch',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewBranches />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'master-data',
          children: [
            { element: <Navigate to="/dashboard/master-data" replace />, index: true },
            {
              path: 'ledger-party',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <LedgerParty />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-ledger-party',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewLedgerParty />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-ledger-party/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewLedgerParty />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ledger-details/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <LedgerDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'inventory-master',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <InventoryMaster />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-inventory-master',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewInventoryMaster />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-inventory-master/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewInventoryMaster />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'service-master',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ServiceMaster />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-service-master',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewServiceMaster />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-service-master/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewServiceMaster />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'users',
          children: [
            {
              path: 'user',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <User />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-user',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewUser />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-user/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewUser />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'sales-panel',
          children: [
            {
              path: 'user',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesPanel />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-user',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSalesPanel />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'edit-user/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSalesPanel />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'organisation',
          children: [
            {
              path: 'transport',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Transport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-transport',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewTransport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-transport-form',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewTransporForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-transport-form/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewTransporForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-transport-form/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewTransporForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'courier',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Courier />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'sales',
          children: [
            {
              path: 'invoice-list',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Sales />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-invoice',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSales />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSales />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSales />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSales />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-bill',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSalesBill />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-bill/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSalesBill />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-bill/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSalesBill />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-bill/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewSalesBill />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-export-invoice',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewExportInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-export-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewExportInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-export-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewExportInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-export-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewExportInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-commercial-invoice',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewCommercialInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-commercial-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewCommercialInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-commercial-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewCommercialInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-commercial-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewCommercialInvoice />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'delivery',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Delivery />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-delivery/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Delivery />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-delivery/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Delivery />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-delivery/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Delivery />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'credit-note',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <CreditNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'change-credit-note/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <CreditNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-credit-note/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <CreditNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-credit-note/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <CreditNote />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'sales-order',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesOrder />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'edit-sales-order/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesOrder />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-sales-order/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesOrder />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-sales-order/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesOrder />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'performa-invoice',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PerformaInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-performa-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PerformaInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-performa-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PerformaInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-performa-invoice/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PerformaInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'estimation',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Estimations />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-estimation/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Estimations />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-estimation/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Estimations />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-estimation/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Estimations />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'debit-note',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DebitNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-debit-note/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DebitNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-debit-note',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  {/* <DebitNoteForm /> */}
                  <DebitNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-debit-note/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  {/* <DebitNoteForm /> */}
                  <DebitNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-debit-note/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  {/* <DebitNoteForm /> */}
                  <DebitNote />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'other-income',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <OtherIncome />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-other-income/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <OtherIncomeForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-other-income/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <OtherIncomeForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-other-income/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <OtherIncomeForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-other-income',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <OtherIncomeForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'stock-transfer',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <StockTransfer />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'edit-stock-transfer/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <StockTransferForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duplicate-stock-transfer/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <StockTransferForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-stock-transfer/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <StockTransferForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-stock-transfer',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <StockTransferForm />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'tax',
          children: [
            {
              path: 'gst',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Gst />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'gstr-3b',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Gstr3B />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'gstr-1',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Gstr1 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'gstr-1-recon',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Gstr1Recon />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'download-gstr-1',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DownloadGSTR1 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'gstr-9',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <GSTR9 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'pmt-6',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ITC04 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ITC-04',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PMT6 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'gstr-2a',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <GSTR2A />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'download-gst-data',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DownloadGstData />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'table-gstr3',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <TableGst3 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'table-gstr2',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <TableGst2 />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tds',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <TDS />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'tcs',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <TCS />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'incomeTax',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <IncomeTax />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'vat',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <VAT />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'duty',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Duty />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PurchasebyVendor',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PurchasebyVendor />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PurchasebyItem',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PurchasebyItem />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PurchasebyPerson',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PurchasebyPerson />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PurchasebyLocation',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PurchasebyLocation />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PurchaseMSME',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PurchaseMSME />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'Payment2Register',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Payment2Register />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'Payment2Unregister',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Payment2Unregister />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PaymentViolation43B',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PaymentViolation43B />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PaymentByMSME',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PaymentByMSME />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PaymentByLocation',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PaymentByLocation />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'AdjustedPayment',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <AdjustedPayment />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'AdvancedPayment',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <AdvancedPayment />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'Payment2Employee',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Payment2Employee />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'UnAdjustedPayment',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <UnAdjustedPayment />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'Salecustomer',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Salecustomer />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'Saleperson',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Saleperson />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SaleItem',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SaleItem />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SalesB2B',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesB2B />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SalesB2C',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesB2C />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SalesRCM',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesRCM />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SalesByProductByCustomer',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesByProductByCustomer />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SalesByProductByService',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesByProductByService />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'SalesMSME',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SalesMSME />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'FixedAsset',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <FixedAsset />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'FixedParty',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <FixedParty />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'FixedBalance',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <FixedBalance />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PayableSundry',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PayableSundry />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PayableSummary',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PayableSummary />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PayableInvoice',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PayableInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'PayableDetail',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PayableDetail />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ExpenseNature',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ExpenseNature />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ExpenseBalance',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ExpenseBalance />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ExpenseLocation',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ExpenseLocation />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ExpensePerson',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ExpensePerson />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ExpenseMSME',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ExpenseMSME />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableBalance',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableBalance />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ARSummary',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ARSummary />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ARDetail',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ARDetail />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableRetainer',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableRetainer />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableEstimate',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableEstimate />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableInvoice',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableBad',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableBad />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableBank',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableBank />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableCustomer',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableCustomer />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableSummary',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableSummary />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'ReceivableDetail',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <ReceivableDetail />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'DeliveryInventoryLocationReport',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DeliveryInventoryLocationReport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'DeliveryLocatinWiseReport',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DeliveryLocatinWiseReport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'DeliveryVendorWiseReport',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DeliveryVendorWiseReport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'DeliveryTotalProjectCost',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <DeliveryTotalProjectCost />
                </RoleBasedGuard>
              ),
            },
            
          ],
        },
        {
          path: 'report',
          children: [
            { element: <Report />, index: true },
            { path: 'financial', element: <Financial /> },

            { path: 'history', element: <History /> },
            { path: 'GstTable', element: <GstTable /> },
            { path: 'OtherReport', element: <OtherReport /> },
            { path: 'trial-balance', element: <TrialBalance /> },
            { path: 'trial-view', element: <TrailView /> },
            { path: 'profit-loss', element: <ProfitAndLoss /> },
            { path: 'balance-sheet', element: <BalanceSheet /> },
            { path: 'cash-flow', element: <Cashflow /> },
          ],
        },
        {
          path: 'reciept',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Reciept />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-reciept',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewReciept />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-reciept/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewReciept />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-reciept/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewReciept />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'payment',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Payment />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-payment',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewPayment />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit-payment/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewPayment />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-payment/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <NewPayment />
                </RoleBasedGuard>
              ),
            },
            { path: 'new-expense', element: <NewExpense /> },
            { path: 'edit-expense/:id', element: <NewExpense /> },
            { path: 'view-expense/:id', element: <NewExpense /> },
            { path: 'duplicate-expense/:id', element: <NewExpense /> },
          ],
        },

        {
          path: 'banking',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Banking />
                </RoleBasedGuard>
              ),
            },
            { path: 'newbankingtable', element: <BankingStatementTable /> },
            { path: ' bankingform', element: <BankingForm /> },
          ],
        },

        {
          path: 'expense-journal',
          children: [
            { path: '', element: <ExpenseOrJournal /> },
            // { path: 'new-expense-journal', element: <NewExpenseOrJournal /> },
            // { path: 'edit-expense-journal/:id', element: <NewExpenseOrJournal /> },
            // { path: 'view-expense-journal/:id', element: <NewExpenseOrJournal /> },
            // { path: 'duplicate-expense-journal/:id', element: <NewExpenseOrJournal /> },

            { path: 'new-expenses', element: <ExpenseJournal /> },
            { path: 'edit-expenses/:id', element: <ExpenseJournal /> },
            { path: 'view-expenses/:id', element: <ExpenseJournal /> },
            { path: 'duplicate-expenses/:id', element: <ExpenseJournal /> },

            { path: 'new-expense-journals', element: <Journal /> },
            { path: 'edit-expense-journals/:id', element: <Journal /> },
            { path: 'view-expense-journals/:id', element: <Journal /> },
            { path: 'duplicate-expense-journals/:id', element: <Journal /> },

            { path: 'new-expense-Fixassests', element: <Fixassests /> },
            { path: 'edit-expense-Fixassests/:id', element: <Fixassests /> },
            { path: 'view-expense-Fixassests/:id', element: <Fixassests /> },
            { path: 'duplicate-expense-Fixassests/:id', element: <Fixassests /> },
          ],
        },
        {
          path: 'purchase',
          children: [
            { path: '', element: <Purchase /> },
            { path: 'new-purchase', element: <NewPurchase /> },
            { path: 'edit-purchase/:id', element: <NewPurchase /> },
            { path: 'duplicate-purchase/:id', element: <NewPurchase /> },
            { path: 'view-purchase/:id', element: <NewPurchase /> },
            { path: 'purchase-bill', element: <PurchaseBill /> },
            { path: 'edit-purchase-bill/:id', element: <PurchaseBill /> },
            { path: 'duplicate-purchase-bill/:id', element: <PurchaseBill /> },
            { path: 'view-purchase-bill/:id', element: <PurchaseBill /> },
            { path: 'purchase-export', element: <PurchaseExport /> },
            { path: 'duplicate-purchase-export/:id', element: <PurchaseExport /> },
            { path: 'edit-purchase-export/:id', element: <PurchaseExport /> },
            { path: 'view-purchase-export/:id', element: <PurchaseExport /> },
            { path: 'purchase-order', element: <PurchaseOrder /> },
            { path: 'edit-purchase-order/:id', element: <PurchaseOrder /> },
            { path: 'duplicate-purchase-order/:id', element: <PurchaseOrder /> },
            { path: 'view-purchase-order', element: <PurchaseOrder /> },

            { path: 'InwardDeliveryChallan', element: <InwardDeliveryChallan /> },
            { path: 'edit-InwardDeliveryChallan/:id', element: <InwardDeliveryChallan /> },
            { path: 'duplicate-InwardDeliveryChallan/:id', element: <InwardDeliveryChallan /> },
            { path: 'view-InwardDeliveryChallan/:id', element: <InwardDeliveryChallan /> },
          ],
        },
        {
          path: 'job-project',
          children: [
            { path: '', element: <JobProject /> },
            { path: 'new-job', element: <NewJobs /> },
            { path: 'new-project', element: <NewProjects /> },
            { path: 'edit-job-project/:id', element: <NewJobs /> },
            { path: 'view-job-project/:id', element: <NewJobs /> },
            { path: 'duplicate-job-project/:id', element: <NewJobs /> },
          ],
        },

        {
          path: 'barcode',
          children: [
            // { path: '', element: <JobProject /> },
            { path: 'generate-barcode', element: <BarcodeGenerator /> }, 
          ],
        },

        
        {
          path: 'ecommerce-OnlineSales',
          children: [
            { path: '', element: <EcommerceOnlineSales /> },
            { path: 'ecom-file', element: <EcomFile /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            {
              path: 'account',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <UserAccount />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        // {
        //   path: 'hr-employee',
        //   children: [
        //     { path: '', element: <Hr /> },
        //     { path: 'new-employee-salary', element: <EmployeeAndSalary /> },
        //   ],
        // },
        {
          path: 'employee-salary',
          children: [
            { path: '', element: <Employee /> },
            { path: 'new-employee-salary', element: <EmployeeAndSalary /> },
            { path: 'edit-employee-salary/:id', element: <EmployeeAndSalary /> },
            { path: 'form-employee-salary', element: <SalaryForm /> },
          ],
        },

        {
          path: 'marketing',
          children: [{ path: '', element: <Marketing /> }],
        },
        {
          path: 'funding',
          children: [{ path: '', element: <Funding /> }],
        },
        {
          path: 'virtual',
          children: [{ path: '', element: <Virtual /> }],
        },
        {
          path: 'Pricing',
          children: [{ path: '', element: <Pricing /> }],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'terms-of-services', element: <TermsofServices /> },
        // { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'pricing-plan', element: <PlanComparison /> },
        { path: 'payment', element: <Payment /> },
        { path: 'banking', element: <Banking /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'pricing-plans', element: <Price /> },
        { path: 'report-an-issue', element: <ReportAnIssue /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const TermsofServices = Loadable(lazy(() => import('../pages/dashboard/TermsofServices')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/dashboard/PrivacyPolicy')));
const CancellationRefund = Loadable(lazy(() => import('../pages/dashboard/CancellationRefund')));
// const Landing = Loadable(lazy(() => import('../pages/Landing')));
// const Offer = Loadable(lazy(() => import('../pages/Offer')));
const Price = Loadable(lazy(() => import('../pages/PricingPlans')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

const PricingPlanLink = Loadable(lazy(() => import('../pages/dashboard/PricingPlanLink')));
const FeaturesGstBilling = Loadable(lazy(() => import('../pages/dashboard/FeaturesGstBilling')));
const HomeRegister = Loadable(lazy(() => import('../pages/dashboard/HomeRegister')));

// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
// const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/InventoryMaster')));
// const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const User = Loadable(lazy(() => import('../pages/dashboard/User')));
const NewUser = Loadable(lazy(() => import('../pages/dashboard/NewUser')));
const SalesPanel = Loadable(lazy(() => import('../pages/dashboard/SalesPanel')));
const NewSalesPanel = Loadable(lazy(() => import('../pages/dashboard/NewSalesPanel')));
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/NewInventoryMaster')));
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// master data
const LedgerParty = Loadable(lazy(() => import('../pages/dashboard/LedgerParty')));
const NewLedgerParty = Loadable(lazy(() => import('../pages/dashboard/NewLedgerParty')));
const LedgerDetails = Loadable(lazy(() => import('../pages/dashboard/LedgerDetails')));
const InventoryMaster = Loadable(lazy(() => import('../pages/dashboard/InventoryMaster')));
const NewInventoryMaster = Loadable(lazy(() => import('../pages/dashboard/NewInventoryMaster')));
const ServiceMaster = Loadable(lazy(() => import('../pages/dashboard/ServiceMaster')));
const NewServiceMaster = Loadable(lazy(() => import('../pages/dashboard/NewServiceMaster')));
// settings
const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')));
const ProfileSetting = Loadable(lazy(() => import('../pages/dashboard/ProfileSetting')));
const SalesSettings = Loadable(lazy(() => import('../pages/dashboard/SalesSettings')));
const TransportSettings = Loadable(lazy(() => import('../pages/dashboard/TransportSetting')));
const TaxSetting = Loadable(lazy(() => import('../pages/dashboard/tax/TaxSetting')));
const PurchaseSettings = Loadable(lazy(() => import('../pages/dashboard/PurchaseSettings')));
const ExpenseSetting = Loadable(lazy(() => import('../pages/dashboard/ExpenseSetting')));
const ReceiptSetting = Loadable(lazy(() => import('../pages/dashboard/ReceiptSetting')));
const JobProjectSetting = Loadable(lazy(() => import('../pages/dashboard/JobProjectSetting')));
const WhatsUpSetting = Loadable(lazy(() => import('../pages/dashboard/WhatsUpSetting')));
const EmailSetting = Loadable(lazy(() => import('../pages/dashboard/EmailSetting')));
const IndustriesSetting = Loadable(lazy(() => import('../pages/dashboard/IndustriesSetting')));
// branch
const Branches = Loadable(lazy(() => import('../pages/dashboard/Branches')));
const NewBranches = Loadable(lazy(() => import('../pages/dashboard/NewBranches')));

// organisation
const Transport = Loadable(lazy(() => import('../pages/dashboard/Transport')));
const Courier = Loadable(lazy(() => import('../pages/dashboard/Courier')));
const NewTransport = Loadable(lazy(() => import('../pages/dashboard/NewTransport')));
const NewTransporForm = Loadable(
  lazy(() => import('../sections/@dashboard/organisation/newtransport/NewTransportForm'))
);
// sales
const Sales = Loadable(lazy(() => import('../pages/dashboard/Sales')));
const NewSales = Loadable(lazy(() => import('../pages/dashboard/NewSales')));
const NewSalesBill = Loadable(lazy(() => import('../pages/dashboard/NewSalesBill')));
const NewExportInvoice = Loadable(lazy(() => import('../pages/dashboard/NewExportInvoice')));
const NewCommercialInvoice = Loadable(lazy(() => import('../pages/dashboard/NewCommercialInvoice')));
const Delivery = Loadable(lazy(() => import('../pages/dashboard/Delivery')));
const InwardDeliveryChallan = Loadable(lazy(() => import('../pages/dashboard/InwardDeliveryChallan')));
const CreditNote = Loadable(lazy(() => import('../pages/dashboard/CreditNote')));
const SalesOrder = Loadable(lazy(() => import('../pages/dashboard/SalesOrder')));
const PerformaInvoice = Loadable(lazy(() => import('../pages/dashboard/PerformaInvoice')));
const Estimations = Loadable(lazy(() => import('../pages/dashboard/Estimations')));
const StockTransfer = Loadable(lazy(() => import('../pages/dashboard/StockTransfer')));
const StockTransferForm = Loadable(lazy(() => import('../pages/dashboard/StockTransferForm')));

// tax
const Gst = Loadable(lazy(() => import('../pages/dashboard/tax/Gst')));
const Gstr1 = Loadable(lazy(() => import('../pages/dashboard/tax/Gstr1')));
const Gstr1Recon = Loadable(lazy(() => import('../pages/dashboard/tax/Gstr1Recon')));
const DownloadGSTR1 = Loadable(lazy(() => import('../pages/dashboard/tax/DownloadGSTR1')));
const GSTR2A = Loadable(lazy(() => import('../pages/dashboard/tax/GSTR2A')));
const DownloadGstData = Loadable(lazy(() => import('../pages/dashboard/tax/DownloadGstData')));
const TableGst3 = Loadable(lazy(() => import('../pages/dashboard/tax/TableGst3')));
const TableGst2 = Loadable(lazy(() => import('../pages/dashboard/tax/TableGst2')));
const Gstr3B = Loadable(lazy(() => import('../pages/dashboard/tax/Gstr3B')));
const GSTR9 = Loadable(lazy(() => import('../pages/dashboard/tax/GSTR9')));
const PMT6 = Loadable(lazy(() => import('../pages/dashboard/tax/PMT6')));
const ITC04 = Loadable(lazy(() => import('../pages/dashboard/tax/ITC04')));
const TDS = Loadable(lazy(() => import('../pages/dashboard/tax/TDS')));
const TCS = Loadable(lazy(() => import('../pages/dashboard/TCS')));
const VAT = Loadable(lazy(() => import('../pages/dashboard/VAT')));
const Duty = Loadable(lazy(() => import('../pages/dashboard/Duty')));

const IncomeTax = Loadable(lazy(() => import('../pages/dashboard/IncomeTax')));
// debit notes
const DebitNote = Loadable(lazy(() => import('../pages/dashboard/DebitNoteForm')));
const OtherIncome = Loadable(lazy(() => import('../pages/dashboard/OtherIncome')));
const OtherIncomeForm = Loadable(lazy(() => import('../sections/@dashboard/sales/sidebar/OtherIncomeForm')));
// report
const Report = Loadable(lazy(() => import('../pages/dashboard/Report/Report')));
const TrialBalance = Loadable(lazy(() => import('../sections/@dashboard/report/TrialBalances')));
const TrailView = Loadable(lazy(() => import('../sections/@dashboard/report/TrialView')));
const ProfitAndLoss = Loadable(lazy(() => import('../sections/@dashboard/report/profit/ProfitAndLoss')));
const BalanceSheet = Loadable(lazy(() => import('../sections/@dashboard/report/BalanceSheet/BalanceSheet')));
const Financial = Loadable(lazy(() => import('../pages/dashboard/Report/Financial')));
const History = Loadable(lazy(() => import('../pages/dashboard/Report/HistoryTable')));
const GstTable = Loadable(lazy(() => import('../pages/dashboard/Report/GstTable')));
const OtherReport = Loadable(lazy(() => import('../pages/dashboard/Report/OtherReport')));
const Cashflow = Loadable(lazy(() => import('../sections/@dashboard/report/cashflow/Casflow')));
const PurchasebyVendor = Loadable(lazy(() => import('../pages/dashboard/Report/PurchaseTable/PurchasebyVendor')));
const PurchasebyItem = Loadable(lazy(() => import('../pages/dashboard/Report/PurchaseTable/PurchasebyItem')));
const PurchasebyPerson = Loadable(lazy(() => import('../pages/dashboard/Report/PurchaseTable/PurchasebyPerson')));
const PurchasebyLocation = Loadable(lazy(() => import('../pages/dashboard/Report/PurchaseTable/PurchasebyLocation')));
const PurchaseMSME = Loadable(lazy(() => import('../pages/dashboard/Report/PurchaseTable/PurchaseMSME')));
const DeliveryInventoryLocationReport = Loadable(
  lazy(() => import('../pages/dashboard/Report/DeliveryChallan/InventoryLocationReport'))
);
const DeliveryLocatinWiseReport = Loadable(
  lazy(() => import('../pages/dashboard/Report/DeliveryChallan/LocatinWiseReport'))
);
const DeliveryVendorWiseReport = Loadable(
  lazy(() => import('../pages/dashboard/Report/DeliveryChallan/VendorWiseReport'))
);

const DeliveryTotalProjectCost= Loadable(
  lazy(() => import('../pages/dashboard/Report/DeliveryChallan/TotalProjectCost'))
);

const Payment2Register = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/Payment2Register')));
const Payment2Unregister = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/Payment2Unregister')));
const PaymentViolation43B = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/PaymentViolation43B')));
const PaymentByMSME = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/PaymentByMSME')));
const PaymentByLocation = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/PaymentByLocation')));
const AdjustedPayment = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/AdjustedPayment')));
const AdvancedPayment = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/AdvancedPayment')));
const Payment2Employee = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/Payment2Employee')));
const UnAdjustedPayment = Loadable(lazy(() => import('../pages/dashboard/Report/Payment/UnAdjustedPayment')));

const SalesB2B = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/SalesB2B')));
const SalesB2C = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/SalesB2C')));
const SalesRCM = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/SalesRCM')));
const SalesByProductByCustomer = Loadable(
  lazy(() => import('../pages/dashboard/Report/SalesTable/SalesByProductByCustomer'))
);
const SalesByProductByService = Loadable(
  lazy(() => import('../pages/dashboard/Report/SalesTable/SalesByProductByService'))
);
const Salecustomer = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/Salecustomer')));
const SaleItem = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/SaleItem')));
const Saleperson = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/Saleperson')));
const SalesMSME = Loadable(lazy(() => import('../pages/dashboard/Report/SalesTable/SalesMSME')));

const FixedAsset = Loadable(lazy(() => import('../pages/dashboard/Report/FixedTable/FixedAsset')));
const FixedParty = Loadable(lazy(() => import('../pages/dashboard/Report/FixedTable/FixedParty')));
const FixedBalance = Loadable(lazy(() => import('../pages/dashboard/Report/FixedTable/FixedBalance')));

const PayableSummary = Loadable(lazy(() => import('../pages/dashboard/Report/Payable/PayableSummary')));
const PayableDetail = Loadable(lazy(() => import('../pages/dashboard/Report/Payable/PayableDetail')));
const PayableSundry = Loadable(lazy(() => import('../pages/dashboard/Report/Payable/PayableSundry')));
const PayableInvoice = Loadable(lazy(() => import('../pages/dashboard/Report/Payable/PayableInvoice')));

const ExpensePerson = Loadable(lazy(() => import('../pages/dashboard/Report/ExpenseTable/ExpensePerson')));
const ExpenseNature = Loadable(lazy(() => import('../pages/dashboard/Report/ExpenseTable/ExpenseNature')));
const ExpenseLocation = Loadable(lazy(() => import('../pages/dashboard/Report/ExpenseTable/ExpenseLocation')));
const ExpenseBalance = Loadable(lazy(() => import('../pages/dashboard/Report/ExpenseTable/ExpenseBalance')));
const ExpenseMSME = Loadable(lazy(() => import('../pages/dashboard/Report/ExpenseTable/ExpenseMSME')));

const ReceivableBalance = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableBalance')));
const ReceivableBad = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableBad')));
const ReceivableBank = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableBank')));
const ARDetail = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ARDetail')));
const ARSummary = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ARSummary')));
const ReceivableRetainer = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableRetainer')));
const ReceivableSummary = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableSummary')));
const ReceivableCustomer = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableCustomer')));
const ReceivableInvoice = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableInvoice')));
const ReceivableDetail = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableDetail')));
const ReceivableEstimate = Loadable(lazy(() => import('../pages/dashboard/Report/Receivable/ReceivableEstimate')));
const ImportForm = Loadable(lazy(() => import('../sections/@dashboard/importdata/ImportForm')));

// reciept
const Reciept = Loadable(lazy(() => import('../pages/dashboard/Reciept')));
const NewReciept = Loadable(lazy(() => import('../pages/dashboard/NewReciept')));
// payment
const Payment = Loadable(lazy(() => import('../pages/dashboard/Payment')));
const NewPayment = Loadable(lazy(() => import('../pages/dashboard/NewPayment')));
const NewExpense = Loadable(lazy(() => import('../pages/dashboard/NewExpenseOrJournal')));
// banking
const Banking = Loadable(lazy(() => import('../pages/dashboard/Banking')));
const BankingStatementTable = Loadable(lazy(() => import('../sections/@dashboard/Banking/BankingStatementTable')));
const BankingForm = Loadable(lazy(() => import('../sections/@dashboard/Banking/BankingForm')));
const BarcodeGenerator= Loadable(lazy(() => import('../sections/@dashboard/barcode/BarcodeGenerator')));
// expense
const ExpenseOrJournal = Loadable(lazy(() => import('../pages/dashboard/ExpenseOrJournal')));
// const NewExpenseOrJournal = Loadable(lazy(() => import('../pages/dashboard/NewExpenseOrJournal')));
const Journal = Loadable(lazy(() => import('../pages/dashboard/Journal')));
const ExpenseJournal = Loadable(lazy(() => import('../pages/dashboard/ExpenseJournal')));

const Fixassests = Loadable(lazy(() => import('../pages/dashboard/Fixassests')));
// purchase
const Purchase = Loadable(lazy(() => import('../pages/dashboard/Purchase')));
const NewPurchase = Loadable(lazy(() => import('../pages/dashboard/NewPurchase')));
const PurchaseBill = Loadable(lazy(() => import('../pages/dashboard/PurchaseBill')));
const PurchaseExport = Loadable(lazy(() => import('../pages/dashboard/PurchaseImport')));
const PurchaseOrder = Loadable(lazy(() => import('../pages/dashboard/PurchaseOrder')));

const Employee = Loadable(lazy(() => import('../pages/dashboard/Employee')));
const EmployeeAndSalary = Loadable(
  lazy(() => import('../sections/@dashboard/employee/employeesalary/EmployeeAndSalary'))
);
const SalaryForm = Loadable(lazy(() => import('../sections/@dashboard/employee/employeesalary/SalaryForm')));

// job
const JobProject = Loadable(lazy(() => import('../pages/dashboard/JobProject')));
const NewJobs = Loadable(lazy(() => import('../pages/dashboard/NewJobs')));
const NewProjects = Loadable(lazy(() => import('../pages/dashboard/NewProjects')));

// flipkart

const EcommerceOnlineSales = Loadable(lazy(() => import('../pages/dashboard/EcommerceOnlineSales')));
const EcomFile = Loadable(lazy(() => import('../sections/@dashboard/ecom/ecomOnlineSales/EcomFile')));

// INVOICE
// const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
// const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
// const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
// const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));
// others
const Funding = Loadable(lazy(() => import('../pages/dashboard/Funding')));
const Hr = Loadable(lazy(() => import('../pages/dashboard/Hr')));
const Marketing = Loadable(lazy(() => import('../pages/dashboard/Marketing')));
const Virtual = Loadable(lazy(() => import('../pages/dashboard/Virtual')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const ReportAnIssue = Loadable(lazy(() => import('../pages/ReportAnIssue')));
const Blog = Loadable(lazy(() => import('../pages/Blog')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const PlanComparison = Loadable(lazy(() => import('../pages/PlanComparison')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const PaymentSuccess = Loadable(lazy(() => import('../pages/payment/Success')));
const PaymentCancle = Loadable(lazy(() => import('../pages/payment/Cancle')));
