import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import { m } from 'framer-motion';
import React, { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  DialogTitle,
  Dialog,
  // DialogContent,
  DialogActions,
  TextField,
  Box,
  Card,
  Typography,
  // Link,
  // Stack,
  Button,
  // Container,
  Checkbox,
} from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// components
import Page from '../components/Page';
import { varFade } from '../components/animate';
// import { RHFTextField, FormProvider } from '../components/hook-form';
import { PATH_AUTH } from '../routes/paths';
// import Iconify from '../components/Iconify';
import axios from '../utils/axios';
// import useIsMountedRef from '../hooks/useIsMountedRef';
// import ImgMediaCard from '../components/ImgMediaCard';
import useResponsive from '../hooks/useResponsive';
import BgImg from '../image/bgImg.png';
import Footer from './Footer';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  width: '100%',
  margin: 'auto',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
  },
}));

// const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
//   paddingTop: theme.spacing(18),
//   paddingBottom: theme.spacing(8),
//   paddingLeft: useResponsive('up', 'lg') ? theme.spacing(16) : '',

//   [theme.breakpoints.up('md')]: {
//     margin: 'auto',
//   },
// }));

// const HeroImgStyle = styled(m.img)(() => ({
//   width: '90%',
// }));

const basic = [
  { id: 3, name: 'Sub users/user controll' },
  { id: 1, name: ' Branch Additions' },
  { id: 5, name: 'Scheduled Reports' },
  { id: '✔️', name: ' Employees Salary record using expense journal' },
  { id: '❌', name: ' Seprate fixed assets registered' },
  { id: '❌', name: '  Customized Invoice filed additions' },
  {
    id: '✔️',
    name: '  Estimates, Invoices, Sales Orders, Recurring Invoices, Duplicate Invoice, Credit Notes, Debit Notes',
  },
  { id: '❌', name: ' E-invoice, E-waybill on single click' },
  { id: '✔️', name: ' Manage Clients, Bills of Supply, Purchase Order, Vendor Credits, Sundry Creditors' },
  { id: '❌', name: ' Online Payments, Generate Payment Links, Payment Reminders, Delivery Challans, e-way Bills' },
  { id: '✔️', name: ' Expenses, User Tracking, Direct Bank, Import PDF Statements, Manual Journals' },
  {
    id: '✔️',
    name: ' Chart of Accounts, Sub Accounts, Pre Defined User Roles, Multi Lingual Transactions, Audit Trails, Reports',
  },
  { id: '✔️', name: ' Bills Of Entry, Project Invoices, Project Tasks, Project Expenses, Bulk Updates' },
  {
    id: '✔️',
    name: ' Transaction tracking using transaction history, Recurring Expenses, Custom View, Custom Fields, Billing, Retainer Invoices',
  },
  {
    id: '✔️',
    name: ' Basic Multi-currency Handling, Automatic Exchange Rates, Digital Signature, Project Time Tracking, Project Profitability',
  },
  {
    id: '✔️',
    name: ' Multiple invoice format, Currency Adjustments, Journal Templates, Recurring Journals, Recurring Bills',
  },
  {
    id: '✔️',
    name: ' Customer Approval, Sales Approval, Customer Approval, Contextual Chat, Purchase Approval, pending approval',
  },
  { id: 'Up to 10', name: ' Custom Roles, Workflow Rules, Advance reconcilations.' },
  { id: '✔️', name: ' StartupKhata Apps,Twilio' },
  { id: '❌', name: ' Track GST, GST Returns, GST Filing, GST Payments, Auto GST Calculations.' },
  { id: '❌', name: 'GST filling through whatsapps on single click and fully automated.' },
  { id: '✔️', name: '  Email Support' },
  { id: '❌', name: ' Voice Support' },
  { id: '❌', name: ' Chat Support' },
  { id: '❌', name: ' Vendor Portal' },
  { id: '❌', name: ' Multi-Transaction, Pre fix Number Series,Custom Status' },
  { id: '❌', name: ' Custom Buttons and Links, Validation Rules, Related Lists, WebTabs' },
  { id: '❌', name: ' Custom Reports, Other Apps' },
];
const Standard = [
  { id: 5, name: 'Sub users/user controll' },
  { id: 3, name: ' Branch Additions' },
  { id: 10, name: 'Scheduled Reports' },
  { id: '✔️', name: ' Employees Salary record using expense journal' },
  { id: 10, name: ' Seprate fixed assets registered' },
  { id: 5, name: '  Customized Invoice filed additions' },
  {
    id: '✔️',
    name: '  Estimates, Invoices, Sales Orders, Recurring Invoices, Duplicate Invoice, Credit Notes, Debit Notes',
  },
  { id: '✔️', name: ' E-invoice, E-waybill on single click' },
  { id: '✔️', name: ' Manage Clients, Bills of Supply, Purchase Order, Vendor Credits, Sundry Creditors' },
  { id: '✔️', name: ' Online Payments, Generate Payment Links, Payment Reminders, Delivery Challans, e-way Bills' },
  { id: '✔️', name: ' Expenses, User Tracking, Direct Bank, Import PDF Statements, Manual Journals' },
  {
    id: '✔️',
    name: ' Chart of Accounts, Sub Accounts, Pre Defined User Roles, Multi Lingual Transactions, Audit Trails, Reports',
  },
  { id: '✔️', name: ' Bills Of Entry, Project Invoices, Project Tasks, Project Expenses, Bulk Updates' },
  {
    id: '✔️',
    name: ' Transaction tracking using transaction history, Recurring Expenses, Custom View, Custom Fields, Billing, Retainer Invoices',
  },
  {
    id: '✔️',
    name: ' Basic Multi-currency Handling, Automatic Exchange Rates, Digital Signature, Project Time Tracking, Project Profitability',
  },
  {
    id: '✔️',
    name: ' Multiple invoice format, Currency Adjustments, Journal Templates, Recurring Journals, Recurring Bills',
  },
  {
    id: '✔️',
    name: ' Customer Approval, Sales Approval, Customer Approval, Contextual Chat, Purchase Approval, pending approval',
  },
  { id: 'Up to 50', name: ' Custom Roles, Workflow Rules, Advance reconcilations.' },
  { id: '✔️', name: ' StartupKhata Apps,Twilio' },
  { id: '✔️', name: ' Track GST, GST Returns, GST Filing, GST Payments, Auto GST Calculations.' },
  { id: '✔️', name: 'GST filling through whatsapps on single click and fully automated.' },
  { id: '✔️', name: '  Email Support' },
  { id: '✔️', name: ' Voice Support' },
  { id: '❌', name: ' Chat Support' },
  { id: '❌', name: ' Vendor Portal' },
  { id: '✔️', name: ' Multi-Transaction, Pre fix Number Series,Custom Status' },
  { id: '✔️', name: ' Custom Buttons and Links, Validation Rules, Related Lists, WebTabs' },
  { id: '❌', name: ' Custom Reports, Other Apps' },
];
const Premium = [
  { id: 'Unlimited', name: 'Sub users/user controll' },
  { id: 'Unlimited', name: ' Branch Additions' },
  { id: 'Unlimited', name: 'Scheduled Reports' },
  { id: '✔️', name: ' Employees Salary record using expense journal' },
  { id: 'Unlimited', name: ' Seprate fixed assets registered' },
  { id: 'Unlimited', name: '  Customized Invoice filed additions' },
  {
    id: '✔️',
    name: '  Estimates, Invoices, Sales Orders, Recurring Invoices, Duplicate Invoice, Credit Notes, Debit Notes',
  },
  { id: '✔️', name: ' E-invoice, E-waybill on single click' },
  { id: '✔️', name: ' Manage Clients, Bills of Supply, Purchase Order, Vendor Credits, Sundry Creditors' },
  { id: '✔️', name: ' Online Payments, Generate Payment Links, Payment Reminders, Delivery Challans, e-way Bills' },
  { id: '✔️', name: ' Expenses, User Tracking, Direct Bank, Import PDF Statements, Manual Journals' },
  {
    id: '✔️',
    name: ' Chart of Accounts, Sub Accounts, Pre Defined User Roles, Multi Lingual Transactions, Audit Trails, Reports',
  },
  { id: '✔️', name: ' Bills Of Entry, Project Invoices, Project Tasks, Project Expenses, Bulk Updates' },
  {
    id: '✔️',
    name: ' Transaction tracking using transaction history, Recurring Expenses, Custom View, Custom Fields, Billing, Retainer Invoices',
  },
  {
    id: '✔️',
    name: ' Basic Multi-currency Handling, Automatic Exchange Rates, Digital Signature, Project Time Tracking, Project Profitability',
  },
  {
    id: '✔️',
    name: ' Multiple invoice format, Currency Adjustments, Journal Templates, Recurring Journals, Recurring Bills',
  },
  {
    id: '✔️',
    name: ' Customer Approval, Sales Approval, Customer Approval, Contextual Chat, Purchase Approval, pending approval',
  },
  { id: 'Up to 200', name: ' Custom Roles, Workflow Rules, Advance reconcilations.' },
  { id: '✔️', name: ' StartupKhata Apps,Twilio' },
  { id: '✔️', name: ' Track GST, GST Returns, GST Filing, GST Payments, Auto GST Calculations.' },
  { id: '✔️', name: 'GST filling through whatsapps on single click and fully automated.' },
  { id: '✔️', name: '  Email Support' },
  { id: '✔️', name: ' Voice Support' },
  { id: '✔️', name: ' Chat Support' },
  { id: '✔️', name: ' Vendor Portal' },
  { id: '✔️', name: ' Multi-Transaction, Pre fix Number Series,Custom Status' },
  { id: '✔️', name: ' Custom Buttons and Links, Validation Rules, Related Lists, WebTabs' },
  { id: '✔️', name: ' Custom Reports, Other Apps' },
];
// ----------------------------------------------------------------------

export default function Contact() {
  // const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();
  let chooseButton = 'Sign Up';
  if (isAuthenticated) {
    chooseButton = 'Choose';
  }

  const [subscription, setSubscription] = useState(1);

  const [pay, setPay] = useState(0);

  const [subscriptionType, setSubscriptionType] = useState(0);

  const [check, setCheck] = useState(false);

  const [check1, setCheck1] = useState(false);

  const [check2, setCheck2] = useState(false);

  const [coupon, setCoupon] = useState('');

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (check || check2 || check1) {
      setOpenDialog(true);
    } else {
      setCoupon('');
    }
  }, [check,check2,check1]);

  const submitProcess = async (p, t) => {
    try {
      let localSubscription = parseInt(subscription, 10);
      if (subscriptionType === 1) {
        localSubscription += 1;
      }
      if (window.location.origin !== 'https://www.startupkhata.com') {
        localSubscription = 11;
      }

      const { data } = await axios.post('/subscriptions/pay', {
        amount: p,
        order_id: t,
        coupon,
      });
      localStorage.setItem('amount', pay);
      localStorage.setItem('order_id', subscription);
      localStorage.setItem('transactionId', data.transactionId);
      localStorage.setItem('coupon', coupon);
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (e) {
      if (e.message === 'Not authenticated') {
        navigate('/auth/register');
      }
      console.log(e);
    }
  };

  const isDesktop = useResponsive('up', 'lg');

  const landingSchema = Yup.object().shape({
    customerName: Yup.string().required('This is required field'),
    quoteEmail: Yup.string().email().required('This is required field'),
    quoteMobile: Yup.string().required('This is required field'),
    quoteMessage: Yup.string().required('This is required field'),
  });

  const defaultValues = {
    customerName: '',
    quoteEmail: '',
    quoteMobile: '',
    quoteMessage: '',
  };

  const methods = useForm({
    resolver: yupResolver(landingSchema),
    defaultValues,
  });

  // const isMountedRef = useIsMountedRef();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const onSubmit = async (data) => {
  //   try {
  //     await axios.post('/postQuote', data);
  //     enqueueSnackbar('create success!');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  //   } catch (error) {
  //     if (isMountedRef.current) {
  //       setError('afterSubmit', error);
  //     }
  //   }
  // };

  const [month, setYear] = useState(true);

  const dialoghandleClose = () => {
    setCheck(false)
    setCheck2(false)
    setCheck1(false)
    setCoupon('');
    setOpenDialog(false);
  };

  const dialoghandleSave = () => {
    setOpenDialog(false);
  };

  return (
    <Page title="Pricing Plans" sx={{}}>
      <RootStyle>
        <Dialog
          open={openDialog}
          keepMounted
          onClose={dialoghandleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Enter Coupon Code</DialogTitle>

          <Box
            sx={{
              display: 'flex',
              // mr: 27,
              m: 4,
              alignItems: 'center',
            }}
          >
            {' '}
            <TextField
              size="small"
              label="Enter Coupon Code"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />{' '}
          </Box>
          <DialogActions>
            <Button onClick={dialoghandleClose}>Cancel</Button>
            <Button onClick={dialoghandleSave}>Save</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 5,
            flexDirection: 'column',
            border: '1px solid rgba(0, 0, 0, 0.00)',
            // background: 'linear-gradient(180deg, #F5FBFF 0%, #F9FFFC 100%)',
            gap: 2,
            py: { xs: 20, sm: 24 },
            background: 'linear-gradient(180deg, #05406C 0%, #185D90 100%)',
          }}
        >
          <m.div variants={varFade().inRight}>
            <Typography
              variant="h1"
              sx={{
                // borderRadius: '4px',
                textAlign: 'center',
                color: '#F2F2F2',
                fontFamily: 'Work Sans',
                fontSize: { xs: '44px', sm: '54px', lg: '64px' },
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                flexShrink: 0,
              }}
            >
              Pricing Plans
              {/* <Stack
                    sx={{
                      color: '#00A4E5',
                    }}
                  >
                    {texts[index]}
                  </Stack> */}
            </Typography>
            <br />
            <Typography
              sx={{
                color: '#F2F2F2',
                textAlign: 'center',
                fontFamily: ' Work Sans',
                fontSize: { xs: '16px', sm: '18px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}
            >
              The Perfect Balance of Features and Affordability
            </Typography>
          </m.div>

          <Button
            sx={{
              borderRadius: '4px',
              background: '#39B073',
              color: ' #F9F9F9',
              fontFamily: 'Work Sans',
              fontSize: { xs: '16px', sm: '18px' },
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              p: 1,
            }}
            component={RouterLink}
            to={PATH_AUTH.register}
          >
            Start 14 Day Free Trial
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(201, 201, 201, 0.15)',
            height: '100%',
            width: '100%',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              p: 6,
            }}
          >
            <Box
              sx={{
                height: '50px',
                width: '280px',
                borderRadius: '114px',
                border: '2px solid #05406C',
                background: 'rgba(57, 176, 115, 0.00)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
              }}
            >
              <Button
                onClick={() => {
                  setYear(true);
                  setSubscriptionType(0);
                }}
                value={subscriptionType}
                sx={{
                  borderRadius: '114px',
                  border: '2px solid rgba(5, 64, 108, 0.00)',
                  background: month ? '#05406C' : '',
                  color: month ? '#fff' : '#05406C',
                  textAlign: 'center',
                  fontFamily: 'Work Sans',
                  fontSize: { xs: '20px', sm: '24px' },
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  width: '140px',
                }}
              >
                Monthly
              </Button>
              <Button
                onClick={() => {
                  setYear(false);
                  setSubscriptionType(1);
                }}
                sx={{
                  borderRadius: '114px',
                  border: '2px solid rgba(5, 64, 108, 0.00)',
                  background: month ? '' : '#05406C',
                  color: month ? '#05406C' : '#fff',
                  textAlign: 'center',
                  fontFamily: 'Work Sans',
                  fontSize: { xs: '20px', sm: '24px' },
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  width: '140px',
                }}
              >
                Yearly
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: isDesktop ? 'space-evenly' : 'center',
              flexDirection: isDesktop ? 'flex' : 'column',
              alignItems: isDesktop ? 'center' : 'center',
              gap: { xs: 6, lg: 0 },
              width: '100%',
              height: '100%',
              p: 2,
            }}
          >
            <Card
              sx={{
                height: '100%',
                width: isDesktop ? '28%' : '88%',
                borderRadius: '8px',
                boxShadow: '0px 0px 2.1px 1px #05406C',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  position: 'relative',
                  color: 'white',
                }}
              >
                <Box
                  style={{ backgroundImage: `url(${BgImg})` }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'normal',
                    fontSize: '34px',
                    height: { xs: '140px', lg: '190px', xl: '200px' },
                    width: { xs: '100%', lg: '500px', xl: '100%' },
                    pt: { xs: 1, sm: 2.4, lg: 2.8 },
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                >
                  <p>BASIC</p>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    mt: { xs: 6, sm: 16, lg: 6.8 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '188px',
                      height: '68px',
                      bgcolor: 'white',
                      borderRadius: '8px',
                      color: '#05406C',
                      boxShadow: '1px 1px 6px 1px gray',
                      flexDirection:'column'
                    }}
                  >
                    <Box sx={{width:'100%' , mt:1, ml:2}}>
                      <del>{month ? <h3>₹ 599</h3> : <h3>₹ 6199</h3>}</del>
                    </Box>
                 
                    <Box
                      sx={{
                        display: 'flex',         
                        height: '100%',
                        width: '164px',
                        fontWeight: 'bold',
                        fontSize: '18px',
            
                      }}
                    >
                      <p>₹</p>
                      {month ? <h1>299</h1> : <h1>3099</h1>}
                      <small style={{ paddingTop: 20, color: '#39B073' }}>{month ? '/per month' : '/year'}</small>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ pb: 2, mt: { xs: 4, sm: 8, lg: 0 } }}>
                {basic.map((item) => {
                  return (
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', pb: 2, width: '100%' }}>
                        <Box sx={{ width: '20%', pl: { xs: 2, sm: 6, md: 8, lg: 2 }, mr: { xs: 0, sm: 2, lg: 3.6 } }}>
                          <p>{item.id}</p>
                        </Box>
                        <Box sx={{ width: '80%', mr: { xs: 0, lg: 1 } }}>
                          {' '}
                          <p>{item.name}</p>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                <Checkbox size="small" checked={check} onChange={(e) => setCheck(e.target.checked)} />
                <small>Apply Coupon Code</small>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    borderRadius: '4px',
                    background: '#39B073',
                    color: ' #F9F9F9',
                    fontFamily: 'Work Sans',
                    fontSize: { xs: '18px', sm: '22px', lg: '18px' },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    px: { xs: 4, sm: 4.8, lg: 3.4 },
                    py: { xs: 1, sm: 1.6, lg: 1 },
                    mb: 2,
                  }}
                  onClick={() => {
                    setSubscription(subscriptionType === 0 ? 1 : 4);
                    setPay(subscriptionType === 0 ? 299 : 3099);
                    submitProcess(subscriptionType === 0 ? 299 : 3099, subscriptionType === 0 ? 1 : 4);
                  }}
                >
                  Get Plan
                </Button>
              </Box>
            </Card>
            <Card
              sx={{
                height: '100%',
                width: isDesktop ? '28%' : '88%',
                borderRadius: '8px',
                boxShadow: '0px 0px 2.1px 1px #05406C',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  position: 'relative',
                  color: 'white',
                }}
              >
                <Box
                  style={{ backgroundImage: `url(${BgImg})` }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'normal',
                    fontSize: '34px',
                    height: { xs: '140px', lg: '190px', xl: '200px' },
                    width: { xs: '100%', lg: '500px', xl: '100%' },
                    pt: { xs: 1, sm: 2.4, lg: 2.8 },
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                >
                  <p>STANDARD</p>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    mt: { xs: 6, sm: 16, lg: 6.8 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '188px',
                      height: '68px',
                      bgcolor: 'white',
                      borderRadius: '8px',
                      color: '#05406C',
                      boxShadow: '1px 1px 6px 1px gray',
                      flexDirection:'column'
                    }}
                  >
                    <Box sx={{width:'100%' , mt:1, ml:2}}>
                      <del>{month ? <h3>₹ 1199</h3> : <h3>₹ 13399</h3>}</del>
                    </Box>
                 
                    <Box
                      sx={{
                        display: 'flex',
                        height: '100%',
                        width: '164px',
                        fontWeight: 'bold',
                        fontSize: '18px',
            
                      }}
                    >
                      <p>₹</p>
                      {month ? <h1>599</h1> : <h1>6699</h1>}
                      <small style={{ paddingTop: 20, color: '#39B073' }}>{month ? '/per month' : '/year'}</small>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ pb: 2, mt: { xs: 4, sm: 8, lg: 0 } }}>
                {Standard.map((item) => {
                  return (
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', pb: 2, width: '100%' }}>
                        <Box sx={{ width: '20%', pl: { xs: 2, sm: 6, md: 8, lg: 2 }, mr: { xs: 0, sm: 2, lg: 3.6 } }}>
                          <p>{item.id}</p>
                        </Box>
                        <Box sx={{ width: '80%', mr: { xs: 0, lg: 1 } }}>
                          {' '}
                          <p>{item.name}</p>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                <Checkbox size="small" checked={check1} onChange={(e) => setCheck1(e.target.checked)} />
                <small>Apply Coupon Code</small>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    borderRadius: '4px',
                    background: '#39B073',
                    color: ' #F9F9F9',
                    fontFamily: 'Work Sans',
                    fontSize: { xs: '18px', sm: '22px', lg: '18px' },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    px: { xs: 4, sm: 4.8, lg: 3.4 },
                    py: { xs: 1, sm: 1.6, lg: 1 },
                    mb: 2,
                  }}
                  onClick={() => {
                    setSubscription(subscriptionType === 0 ? 2 : 5);
                    setPay(subscriptionType === 0 ? 599 : 6699);
                    submitProcess(subscriptionType === 0 ? 599 : 6699, subscriptionType === 0 ? 2 : 5);
                  }}
                >
                  Get Plan
                </Button>
              </Box>
            </Card>
            <Card
              sx={{
                height: '100%',
                width: isDesktop ? '28%' : '88%',
                borderRadius: '8px',
                boxShadow: '0px 0px 2.1px 1px #05406C',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  position: 'relative',
                  color: 'white',
                }}
              >
                <Box
                  style={{ backgroundImage: `url(${BgImg})` }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'normal',
                    fontSize: '34px',
                    height: { xs: '140px', lg: '190px', xl: '200px' },
                    width: { xs: '100%', lg: '500px', xl: '100%' },
                    pt: { xs: 1, sm: 2.4, lg: 2.8 },
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                >
                  <p>PREMIUM</p>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    mt: { xs: 6, sm: 16, lg: 6.8 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '188px',
                      height: '68px',
                      bgcolor: 'white',
                      borderRadius: '8px',
                      color: '#05406C',
                      boxShadow: '1px 1px 6px 1px gray',
                      flexDirection:'column'
                    }}
                  >
                    <Box sx={{width:'100%' , mt:1, ml:2}}>
                      <del>{month ? <h3>₹ 1999</h3> : <h3>₹ 22999</h3>}</del>
                    </Box>
                 
                    <Box
                      sx={{
                        display: 'flex',      
                        height: '100%',
                        width: '164px',
                        fontWeight: 'bold',
                        fontSize: '18px',
  
                      }}
                    >
                      <p>₹</p>
                      {month ? <h1>999</h1> : <h1>11499</h1>}
                      <small style={{ paddingTop: 20, color: '#39B073' }}>{month ? '/per month' : '/year'}</small>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ pb: 2, mt: { xs: 4, sm: 8, lg: 0 } }}>
                {Premium.map((item) => {
                  return (
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', pb: 2, width: '100%' }}>
                        <Box sx={{ width: '20%', pl: { xs: 1, sm: 4, md: 8, lg: 2 }, mr: { xs: 4, sm: 2, lg: 3.6 } }}>
                          <p>{item.id}</p>
                        </Box>
                        <Box sx={{ width: '80%', mr: 1 }}>
                          {' '}
                          <p>{item.name}</p>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                <Checkbox size="small" checked={check2} onChange={(e) => setCheck2(e.target.checked)} />
                <small>Apply Coupon Code</small>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    borderRadius: '4px',
                    background: '#39B073',
                    color: ' #F9F9F9',
                    fontFamily: 'Work Sans',
                    fontSize: { xs: '18px', sm: '22px', lg: '18px' },
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    px: { xs: 4, sm: 4.8, lg: 3.4 },
                    py: { xs: 1, sm: 1.6, lg: 1 },
                    mb: 2,
                  }}
                  onClick={() => {
                    setSubscription(subscriptionType === 0 ? 3 : 6);
                    setPay(subscriptionType === 0 ? 999 : 11499);
                    submitProcess(subscriptionType === 0 ? 999 : 11499, subscriptionType === 0 ? 3 : 6);
                  }}
                >
                  Get Plan
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
        <Footer />
      </RootStyle>
    </Page>
  );
}
