import React from 'react';
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Container, Box, Button, styled } from '@mui/material';
// import PhoneIcon from '@mui/icons-material/Phone';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { useTheme } from '@mui/material/styles';

// Other imports...
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';

import cssStyles from '../../utils/cssStyles';
import { HEADER } from '../../config';
import { PATH_AUTH } from '../../routes/paths';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  boxShadow: theme.customShadows.z8,
}));


const MainHeader = () => {
  const navigate = useNavigate();
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'md');
  const isHome = pathname === '/';
  const { isAuthenticated, logout, user } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  let isSubscribed = true;
  if (isAuthenticated) {
    const currentDate = new Date();
    const lastDate = new Date(user.subscription_end);
    if (currentDate.getTime() > lastDate.getTime() || lastDate.toString() === 'Invalid Date') {
      isSubscribed = false;
    }
  } else {
    isSubscribed = false;
  }
  return (
    <AppBar component={'nav'} sx={{ boxShadow: 0, bgcolor: 'transparent', px: { xs: 0, lg: 6 } }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {isDesktop && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box>
                <Logo sx={{ width: '10%' }} />
              </Box>

              <Box sx={{ marginLeft: '120px' }}>
                <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
              </Box>
            </Box>
          )}
          {isSubscribed && (
            <Button component={RouterLink} to={PATH_AUTH.login} sx={{ color: 'common.black', opacity: 0.78 }}>
              Dashboard
            </Button>
          )}

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}

        </Container>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: { xs: 1, sm: 3 },
            width: { xs: '800px', sm: '400px' },
          }}
        >
          {!isAuthenticated && (
            <Button
              sx={{
                color: '#181818',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
              }}
              component={RouterLink}
              to={PATH_AUTH.login}
            >
              Login
            </Button>
          )}

          {isAuthenticated && (
            <Button
              onClick={handleLogout}
              sx={{
                color: '#181818',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
              }}
            >
              Logout
            </Button>
          )}

          <Button
            sx={{
              color: '#F9F9F9',
              fontFamily: 'Work Sans',
              fontSize: { xs: '16', sm: '18px' },
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
              borderRadius: '4px',
              background: '#39B073',
            }}
            component={RouterLink}
            to={PATH_AUTH.register}
          >
            FREE DEMO
          </Button>
        </Container>
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}

      
    </AppBar>
  );
};

export default MainHeader;
