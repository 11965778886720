
// @mui
import { styled } from '@mui/material/styles';
import {Divider} from '@mui/material';
// routes
// import { PATH_PAGE } from '../../routes/paths';
// components
// import Logo from '../../components/Logo';
// import SocialsButton from '../../components/SocialsButton';
// ----------------------------------------------------------------------

// const LINKS = [
//   {
//     headline: 'StartupKhata',
//     children: [
//       { name: 'About us', href: PATH_PAGE.about },
//       { name: 'Contact us', href: PATH_PAGE.contact },
//       { name: 'FAQs', href: PATH_PAGE.faqs },
//     ],
//   },
//   {
//     headline: 'Legal',
//     children: [
//       { name: 'Terms Of Services', href: PATH_PAGE.termsOfService },
//       { name: 'Privacy Policy', href: PATH_PAGE.PrivacyPolicy },
//       { name: 'Refund Return Policy', href: PATH_PAGE.CancellationRefund },
//     ],
//   },
//   // {
//   //   headline:'Contact',
//   //   children: [
//   //     { name: 'For Support:-support@StartupKhata.com', href: "mailto:support@startupkhata.com" },
//   //     { name:  'For Contact:- info@startupkhata.com', href: 'mailto:info@startupkhata.com' },
//   //     { name: <Typography sx={{fontSize:'13px'}}> RZP-56A, First floor, Gali No. 1, Palam Colony,<br />Raj Nagar - 2, New Delhi, Delhi 110077</Typography>, href: '#' },
//   //   ],
//   // },
// ];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------
// function getCurrentYear() {
//   return new Date().getFullYear();
// }
export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
     
    </RootStyle>
  );
}
